import React, { useState, useEffect } from 'react';
import useDocumentId from "../../hooks/useDocumentId";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; import { errorNotifier, successNotifier } from '../../util/notifications';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdAddCircle, MdEdit, MdPrint } from "react-icons/md";
import { request } from '../../util/fetchAPI';

export default function SupplierForm() {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const { docIdData, fetchdocIdData } = useDocumentId({});
    const [suppliers, setSuppliers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentEditId, setCurrentEditId] = useState(null);

    const navigate = useNavigate();

    // Fetch suppliers from the API
    const fetchSuppliers = async () => {
        try {
            const { data, status } = await request('/suppliers/getAllSuppliers', 'GET');
            if (status === 200) {
                setSuppliers(data);
                setFilteredSuppliers(data);
            }
        } catch (error) {
            errorNotifier("Failed to fetch suppliers.");
        }
    };

    useEffect(() => {
        fetchSuppliers();
    }, []);

    // Update filtered list based on search term
    useEffect(() => {
        const filteredData = suppliers.filter(supplier =>
            supplier.supplierName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            supplier.supplierId.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredSuppliers(filteredData);
    }, [searchTerm, suppliers]);

    // Handle form submission
    const handleFormSubmit = async (formData) => {
        const payload = {
            supplierId: formData.supplierId,
            supplierName: formData.supplierName,
            contact: formData.contact,
            address: formData.address,
            ntn: formData.ntn,
            cnic: formData.cnic,
        };

        try {
            if (isEditMode) {
                // Update existing supplier
                const { data, status } = await request(`/suppliers/updateSupplier/${currentEditId}`, 'PUT', {
                    'Content-Type': 'application/json'
                }, payload);

                if (status === 200) {
                    successNotifier(data.message);
                } else {
                    errorNotifier(data.message);
                }
            } else {
                // Create a new supplier
                const { data, status } = await request('/suppliers/addSupplier', 'POST', {
                    'Content-Type': 'application/json'
                }, payload);

                if (status === 200) {
                    successNotifier(data.message);
                } else {
                    errorNotifier(data.message);
                }
            }

            fetchSuppliers();
            reset();
            setIsEditMode(false);
            setCurrentEditId(null);
        } catch (error) {
            errorNotifier("Error submitting form.");
        }
    };

    // Populate form fields for editing
    const handleEdit = async (id) => {
        try {
            const { data, status } = await request(`/suppliers/getSupplierById/${id}`, 'GET');
            if (status === 200) {
                setValue("supplierId", data.supplierId);
                setValue("supplierName", data.supplierName);
                setValue("contact", data.contact);
                setValue("address", data.address);
                setValue("ntn", data.ntn);
                setValue("cnic", data.cnic);
                setIsEditMode(true);
                setCurrentEditId(id);
            }
        } catch (error) {
            errorNotifier("Failed to fetch supplier details.");
        }
    };

    // Delete a supplier
    const handleDelete = async (id) => {
        try {
            const { data, status } = await request(`/suppliers/deleteSupplier/${id}`, 'DELETE');
            if (status === 200) {
                successNotifier(data.message);
                fetchSuppliers();
            } else {
                errorNotifier(data.message);
            }
        } catch (error) {
            errorNotifier("Failed to delete supplier.");
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        fetchdocIdData("supplier");

    }, []);

    useEffect(() => {
        if (docIdData?.prefix && docIdData?.currentId) {
            setValue("supplierId", `${docIdData.prefix}` + -  + `${docIdData.currentId}`);
        }
    }, [docIdData, setValue]);
    return (
        <div className='flex flex-col justify-center w-full'>
            <div className='Heading'>Add Supplier</div>
            <div className='flex justify-center w-[100%] p-3'>
                <form onSubmit={handleSubmit(handleFormSubmit)} className='forms w-[100%] min-h-[40vh]'>

                    <div className='InputBox'>
                        <div>
                            <label>Supplier ID</label>
                            <input type="text" {...register("supplierId", { required: true })} className={errors.supplierId ? 'errorInput' : ""} readOnly />
                            {errors.supplierId && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                        <div>
                            <label>Supplier Name</label>
                            <input type="text" {...register("supplierName", { required: true })} className={errors.supplierName ? 'errorInput' : ""} />
                            {errors.supplierName && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div>
                            <label>Contact</label>
                            <input type="text" {...register("contact", { required: true })} className={errors.contact ? 'errorInput' : ""} />
                            {errors.contact && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                        <div>
                            <label>Address</label>
                            <input type="text" {...register("address", { required: true })} className={errors.address ? 'errorInput' : ""} />
                            {errors.address && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div>
                            <label>NTN</label>
                            <input type="text" {...register("ntn", { required: true })} className={errors.ntn ? 'errorInput' : ""} />
                            {errors.ntn && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                        <div>
                            <label>CNIC</label>
                            <input type="text" {...register("cnic", { required: true })} className={errors.cnic ? 'errorInput' : ""} />
                            {errors.cnic && <span className='flex items-center justify-start text-red-700 m-1'>Required Field</span>}
                        </div>
                    </div>

                    <div className='Button'>
                        <button>{isEditMode ? "Update" : "Submit"}</button>
                    </div>
                </form>
            </div>

            <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
                <ToastContainer />
                <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Patient List</h1>
                <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <div className="flex items-center">
                                    <label className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <FaSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-4 py-3">#</th>
                                    <th scope="col" className="px-4 py-3">Supplier ID</th>
                                    <th scope="col" className="px-4 py-3">Name</th>
                                    <th scope="col" className="px-4 py-3">Contact</th>
                                    <th scope="col" className="px-4 py-3">Address</th>
                                    <th scope="col" className="px-4 py-3">NTN</th>
                                    <th scope="col" className="px-4 py-3">CNIC</th>
                                    <th scope="col" className="sr-only text-black"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredSuppliers.map((supplier, index) => (
                                    <tr key={supplier._id}>
                                        <td className="px-4 py-3">{index + 1}</td>
                                        <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{supplier.supplierId}</th>
                                        <td className="px-4 py-3">{supplier.supplierName}</td>
                                        <td className="px-4 py-3">{supplier.contact}</td>
                                        <td className="px-4 py-3">{supplier.address}</td>
                                        <td className="px-4 py-3">{supplier.ntn}</td>
                                        <td className="px-4 py-3">{supplier.cnic}</td>
                                        <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                                            <MdEdit className='text-xl' onClick={() => handleEdit(supplier._id)} />
                                            <MdDelete className='text-xl' onClick={() => handleDelete(supplier._id)} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
}
