import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useReceiptById() {
    const [isLoading, setIsLoading] = useState(true);
    const [receiptData, setReceiptData] = useState([]);
    const [error, setError] = useState(null);

    const fetchReceipts = async (id) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/receipt/getPatientReceipt/${id}`, "GET");
            setReceiptData(data || [])
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };


    return { isLoading, receiptData, error, fetchReceipts };
}
