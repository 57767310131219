import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useEmpolyee() {
    const [isLoading, setIsLoading] = useState(true);
    const [employeeData, setEmployeeData] = useState([]);
    const [error, setError] = useState(null);

    const fetchEmployees = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/employee/getAllEmployees`, "GET");
            setEmployeeData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    return { isLoading, employeeData, error, refetch: fetchEmployees };
}
