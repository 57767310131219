import React, { useEffect, useState } from 'react';
import useDocumentId from '../../hooks/useDocumentId';
import {useForm} from 'react-hook-form'
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI'

export default function PatientForm() {
    const {docIdData, fetchdocIdData } = useDocumentId({});
    const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm()

    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg'/></span>)
    }

    const PatientFormHandler = async(data)=>{
        try {

            const payload = {
                ...data,
                accountId:docIdData.accountId,
                prefix:docIdData.prefix,
                patientId:docIdData.currentId,
              };


            const patientData = await request('/patient/addPatient', 'POST', {
                'Content-Type': 'application/json'
            }, {...payload})
            if (patientData.status === 200) {
                alert(patientData.data.message)
            } else {
                alert('Failed to add patient')
            }
        } catch (error) {
            console.error('Error adding patient:', error)
            alert('An error occurred while adding the patient')
        }
    }

    useEffect(() => {
        fetchdocIdData("patients");

      }, []);

      useEffect(() => {
        if (docIdData?.prefix && docIdData?.currentId) {
          setValue("patientId", `${docIdData.prefix}`+  -  + `${docIdData.currentId}`);
        }
      }, [docIdData, setValue]);
    return (
        <div className='FormContainer'>
            <div className='Heading'>
                Add New Patient
            </div>

            <div className='FormWraper'>
                <form onSubmit={handleSubmit(PatientFormHandler)} className='forms'>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">Patient Id</label>
                            <input type="text" name="name" id="name" {...register("patientId", {required:true})} className= {errors.patientId ? 'errorInput' : ""}/>
                            {errors.patientId && errorMessages()}
                        </div>

                        <div>
                            <label htmlFor="name">Patient Name</label>
                            <input type="text" {...register("patientName", {required:true})} className= {errors.patientName ? 'errorInput' : ""}/>
                            {errors.patientName && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">Gender</label>
                            <select
                            {...register("gender", {required:true})}
                            className= {errors.gender ? 'errorInput' : ""}>
                                <option  hidden>Chose...</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                            {errors.gender && errorMessages()}
                        </div>

                        <div>
                            <label >Age</label>
                            <input type="text"  {...register("age", {required:true})}
                            className= {errors.age ? 'errorInput' : ""}/>
                            {errors.age && errorMessages()}
                        </div>


                    </div>

                    <div className='InputBox'>

                    <div>
                            <label >Phone Number</label>
                            <input type="text"  {...register("contact", {required:true})}
                            className= {errors.phoneNumber ? 'errorInput' : ""}/>
                            {errors.phoneNumber && errorMessages()}
                        </div>

                        <div>
                            <label >Occupation</label>
                            <input type="text"  {...register("occupation", {required:true})}
                            className= {errors.occupation ? 'errorInput' : ""}/>
                            {errors.occupation && errorMessages()}
                        </div>

                    </div>


                    <div className='InputBox'>


                        <div>
                            <label >Adress</label>
                            <input type="text"  {...register("address", {required:true})}
                            className= {errors.address ? 'errorInput' : ""}/>
                            {errors.address && errorMessages()}
                        </div>

                    </div>

                    <div className='Button'>
                        <button>
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
