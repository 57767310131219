import React, { useEffect, useState } from 'react'
import CompanyProfileForm from './CompanyProfileForm'
import CompanyProfileView from './CompanyProfileView';
import useCompany from '../../hooks/useCompany';

export default function CompanyProfile() {

  const { isLoading, companyData, error, fetchProfile } = useCompany()

  return (
    <div>
      {companyData ?
      <CompanyProfileView CompanyProfile={companyData}/>
      :
      <CompanyProfileForm/>
      }
    </div>
  )
}
