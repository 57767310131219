import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';


export default function useUser() {
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState([]);
    const [error, setError] = useState(null);

    const fetchUser = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/user/getAllUsers`, "GET");
            setUserData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return { isLoading, userData, error, refetch: fetchUser };
}
