import { useState} from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI'; // Ensure this import is correct and the request function is properly defined in fetchAPI.js

export default function useOPDVisitById() {
    const [isLoading, setIsLoading] = useState(true);
    const [visitData, setVisitData] = useState([]);
    const [error, setError] = useState(null);

    const fetchVisit = async (id) => {
        try {
            setIsLoading(true);
            const response = await request(`/opd/getPatientVisit/${id}`, "GET");
            const { data } = response;
            setVisitData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };


    const updateVisit = async (id, updatedData) => {
        setIsLoading(true);
        setError(null);
        try{
        if(id){
            const response = await request(`/opd/updateVisit/${id}`, "PUT", {"Content-Type": "application/json"}, {...updatedData});
            if (response && response.data) {
                setVisitData(response.data);
            } else {
                throw new Error("Invalid response format");
            }
        }}catch (err) {
          setError(err);
          console.error(err);
          throw err;
        } finally {
          setIsLoading(false);
        }
      };



    return { isLoading, visitData, error, fetchVisit, updateVisit};
}
