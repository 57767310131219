import React, { useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useReceiptDtlById from "../../hooks/useReceiptDtlById";
import "./ReceiptView.css";

export default function ReceiptView() {
  const { isLoading, receiptData, error, fetchReceipts } = useReceiptDtlById();
  const { id } = useParams();
  const { register, setValue, formState: { errors } } = useForm();

  // Fetch receipt data on component mount or id change
  useEffect(() => {
    fetchReceipts(id);
  }, [id]);

  // Extract the first object from receiptData array
  const receipt = Array.isArray(receiptData) && receiptData.length > 0 ? receiptData[0] : {};

  // Populate form fields when receipt changes
  useEffect(() => {
    const date = moment(receipt.createdAt).format("YYYY-MM-DD");
    const time = moment(receipt.createdAt).format("hh:mm A");
    if (receipt) {
      const { amount, discount, paid } = receipt;
      // Calculate payable and pending amounts
      const calculatePaymentDetails = (amount = 0, discount = 0, paid = 0) => {
        const payable = amount - (amount * discount) / 100;
        const pending = payable - paid;
        return {
          payable: payable.toFixed(2),
          pending: pending.toFixed(2),
        };
      };

      // Get payable and pending amounts
      const { payable, pending } = calculatePaymentDetails(amount, discount, paid);

      setValue("receiptId", receipt?.receiptNo || "");
      setValue("Created_At", date + " - " + time || "");
      setValue("source", receipt?.accountsName);
      setValue("reference", `${receipt?.patientData?.prefix}-${receipt?.patientData?.patientId}`);
      setValue("status", receipt?.bankId ? "VERIFIED" : "UNVERIFIED");
      setValue("amount", amount || "");
      setValue("discount", discount || "");
      setValue("payable", payable);
      setValue("paid", paid || "");
      setValue("pending", pending);
      setValue("pendingstatus", receipt?.isPending ? "Pending" : "Paid");
      setValue("Debit_Account", receipt?.bankId ? receipt?.bankName + " - " + receipt?.accountNo : receipt?.accountsId + "-" + receipt?.accountsName);
      setValue("Credit_Account", receipt?.bankId ? receipt?.accountsId + " - " + receipt?.accountsName : "null");
      setValue("receiptType", receipt?.receiptType || "");
      setValue(
        "patientId",
        `${receipt?.patientData?.prefix}-${receipt?.patientData?.patientId}` || ""
      );
      setValue("patientName", receipt?.patientData?.patientName || "");
      setValue("contact", receipt?.patientData?.contact || "");
      setValue("address", receipt?.patientData?.address || "");
      setValue(
        "doctor",
        `${receipt?.doctorData?.employeeName} (${receipt?.doctorData?.prefix} - ${receipt?.doctorData?.employeeId})` || ""
      );
      setValue("specialization", receipt?.doctorData?.specialization || "");
    }
  }, [receipt, setValue]);

  if (error) {
    return <div className="error">Error fetching receipt data: {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="FormContainer">
      <div className="Heading">Edit Receipt Details</div>
      <div className="FormWraper">
        <form className="forms min-h-[80vh] mb-1">
          <div className="font-bold underline">Recepit Information</div>
          <div className="InputBox">
            <div>
              <label htmlFor="Created_At">Created At</label>
              <input type="text" id="Created_At" {...register("Created_At")} disabled />
            </div>
          </div>
          <div className="InputBox">
            <div>
              <label htmlFor="receiptId">Receipt ID</label>
              <input type="text" id="receiptId" {...register("receiptId")} disabled />
            </div>
            <div>
              <label htmlFor="source">Source</label>
              <input type="text" id="source" {...register("source")} disabled />
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="reference">OPD Visit No/Id</label>
              <input type="text" id="reference" {...register("reference")} disabled />
            </div>
            <div>
              <label htmlFor="amount">Amount</label>
              <input type="text" id="amount" {...register("amount")} disabled />
            </div>
          </div>
          <div className="InputBox">
          <div>
              <label htmlFor="amount">Discount%</label>
              <input
                type="text"
                id="discount"
                {...register("discount", { required: true })}
                className={errors.discount ? "errorInput" : ""}
                disabled
              />

            </div>


            <div>
              <label htmlFor="payable">Payable</label>
              <input
                type="text"
                id="payable"
                {...register("payable", { required: true })}
                className={errors.payable ? "errorInput" : ""}
                disabled
              />

            </div>
          </div>
          <div className="InputBox">
          <div>
              <label htmlFor="paid">Paid</label>
              <input
                type="text"
                id="paid"
                {...register("paid", { required: true })}
                className={errors.paid ? "errorInput" : ""}
                disabled
              />

            </div>


            <div>
              <label htmlFor="pending">Pending</label>
              <input
                type="text"
                id="pending"
                {...register("pending", { required: true })}
                className={errors.pending ? "errorInput" : ""}
                disabled
              />

            </div>
          </div>
          <div className="InputBox">
          <div>
              <label htmlFor="pendingstatus">Status</label>
              <input
                type="text"
                id="pendingstatus"
                {...register("pendingstatus", { required: true })}
                className={errors.pendingstatus ? "errorInput" : ""}
                disabled
              />

            </div>
            </div>
          <hr className="border-2 border-gray-400 m-5" />
          <div className="font-bold underline">Accounts</div>
          <div className="InputBox">
            <div>
              <label htmlFor="status">Status</label>
              <input
                type="text"
                id="status"
                {...register("status")}
                className={receipt?.bankId ? "text-green-700 font-bold" : "text-red-700 font-bold"}
                disabled
              />
            </div>
            <div>
              <label htmlFor="receiptType">Current Type</label>
              <input type="text" id="receiptType" {...register("receiptType")} disabled />
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="Debit_Account">Debit Account</label>
              <input type="text" id="Debit_Account" {...register("Debit_Account")}
                className="text-green-700 font-bold"
                disabled />
            </div>
            <div>
              <label htmlFor="Credit_Account">Credit Account</label>
              <input
                type="text"
                id="Credit_Account"
                {...register("Credit_Account")}
                className="text-red-700 font-bold"
                disabled
              />
            </div>
          </div>

          <hr className="border-2 border-gray-400 m-5" />
          <div className="font-bold underline">Payer</div>
          <div className="InputBox">
            <div>
              <label htmlFor="patientId">Patient ID</label>
              <input type="text" id="patientId" {...register("patientId")} disabled />
            </div>
            <div>
              <label htmlFor="patientName">Patient Name</label>
              <input type="text" id="patientName" {...register("patientName")} disabled />
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="contact">Phone Number</label>
              <input type="text" id="contact" {...register("contact")} disabled />
            </div>
            <div>
              <label htmlFor="address">Address</label>
              <input type="text" id="address" {...register("address")} disabled />
            </div>
          </div>

          <hr className="border-2 border-gray-400 m-5" />
          <div className="font-bold underline">Patient Checked By</div>
          <div className="InputBox">
            <div>
              <label htmlFor="doctor">Doctor</label>
              <input type="text" id="doctor" {...register("doctor")} disabled />
            </div>
            <div>
              <label htmlFor="specialization">Specialization</label>
              <input type="text" id="specialization" {...register("specialization")} disabled />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
