import React, { useEffect } from "react";
import printJS from "print-js";
import useOPDVisitById from "../../hooks/useOPDVisitById";
import useCompany from "../../hooks/useCompany";
import "./PreviewModal.css";
// Utility function for payment calculations
const calculatePaymentDetails = (amount = 0, discount = 0, paid = 0) => {
  const payable = amount - (amount * discount) / 100;
  const pending = payable - paid;
  return {
    payable: payable.toFixed(2),
    pending: pending.toFixed(2),
  };
};

export const OPDVisitPrintPreview = ({ id, onClose }) => {
  const { isLoading: isVisitLoading, visitData, error: visitError, fetchVisit } = useOPDVisitById();
  const { isLoading: isCompanyLoading, companyData, error: companyError, refetch: fetchCompanyProfile } = useCompany();

  useEffect(() => {
    if (id) {
      fetchVisit(id);
      fetchCompanyProfile();
    }
  }, [id]);

  const handlePrint = () => {
    if (!visitData || !companyData) {
      console.error("No visit or company data available for printing.");
      return;
    }

    const { amount, discount, paid } = visitData.receiptData[0] || {};
    const { payable, pending } = calculatePaymentDetails(amount, discount, paid);

    const printContent = `
      <div class="opd-slip">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
          <img src="http://localhost:5002/images/${companyData.logo}" alt="Logo" style="width: 80px; height: 80px; border-radius: 15px;">
          <h1 style="margin: 0; text-align: center;">${companyData.companyName}</h1>
        </div>
        <h2 style="text-align: center;">OPD Slip</h2>
        <hr />
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;">
          <p><strong>Visit No/Dated:</strong> ${visitData.opdData?.prefix} - ${visitData.opdData?.visitNo} ${visitData.opdData?.opdDate}</p>
          <p><strong>Patient:</strong> ${visitData.opdData?.patientId?.prefix} - ${visitData.opdData?.patientId?.patientId} ${visitData.opdData?.patientId?.patientName} </p>
          <p><strong>Gender/Age:</strong> ${visitData.opdData?.patientId?.gender} / ${visitData.opdData?.patientId?.age}Y</p>
          <p><strong>Contact:</strong> ${visitData.opdData?.patientId?.contact}</p>
          <p><strong>Doctor:</strong> ${visitData.opdData?.doctorId?.employeeName}</p>
          <p><strong>Specialization:</strong> ${visitData.opdData?.doctorId?.specialization}</p>

        </div>
        <hr />
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;">
                  <p><strong>Receipt No:</strong> ${visitData.receiptData[0]?.receiptNo}</p>
          <p><strong>Amount:</strong> ${amount || 0}.00</p>
          <p><strong>Discount:</strong> ${discount || 0}%</p>
          <p><strong>Payable:</strong> ${payable}</p>
          <p><strong>Paid:</strong> ${paid || 0}.00</p>
          <p><strong>Pending:</strong> ${pending}</p>
        </div>
        <hr />
        <p><strong>Created At:</strong> ${new Date(visitData.opdData?.createdAt).toLocaleString()}</p>

        <div style="
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #ddd;
">
  <p>${companyData.address}</p>
  <p>${companyData.website} - ${companyData.email}</p>
  <p>${companyData.contact1} - ${companyData.contact2}</p>
</div>

      </div>
    `;

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
        .opd-slip {
          font-family: Arial, sans-serif;
          padding: 20px;
        }
        .opd-slip p {
          margin: 5px 0;
        }
        .opd-slip h1, .opd-slip h2 {
          margin: 5px 0;
        }
      `,
    });
  };

  if (isVisitLoading || isCompanyLoading) {
    return <div>Loading...</div>;
  }

  if (visitError || companyError) {
    return <div>Error loading visit or company data.</div>;
  }

  const { amount, discount, paid } = visitData.receiptData[0] || {};
  const { payable, pending } = calculatePaymentDetails(amount, discount, paid);

  return (
    <div className="modal">

      <div className="modal-content">
      <div className="modal-actions">
          <button onClick={handlePrint}>Print</button>
          <button onClick={onClose}>Close</button>
        </div>
        <div className="preview-content">
          <h1>{companyData.companyName}</h1>
          <h2>OPD Slip</h2>
          <hr />
          <div className="grid grid-cols-2">
            <p><strong>Visit No:</strong> {visitData.opdData?.prefix} - {visitData.opdData?.visitNo}</p>
            <p><strong>Date:</strong> {visitData.opdData?.opdDate}</p>
            <p><strong>Visit Type:</strong> {visitData.opdData?.address ? "Home Visit" : "OPD Visit"}</p>
            <p><strong>Patient ID:</strong> {visitData.opdData?.patientId?.prefix} - {visitData.opdData?.patientId?.patientId}</p>
            <p><strong>Patient Name:</strong> {visitData.opdData?.patientId?.patientName}</p>
            <p><strong>Gender:</strong> {visitData.opdData?.patientId?.gender}</p>
            <p><strong>Age:</strong> {visitData.opdData?.patientId?.age}</p>
            <p><strong>Contact:</strong> {visitData.opdData?.patientId?.contact}</p>
            <p><strong>Doctor:</strong> {visitData.opdData?.doctorId?.employeeName}</p>
            <p><strong>Specialization:</strong> {visitData.opdData?.doctorId?.specialization}</p>
            <p><strong>Receipt No:</strong> {visitData.receiptData[0]?.receiptNo}</p>
            <p><strong>Amount:</strong> {amount || 0}.00</p>
            <p><strong>Discount:</strong> {discount || 0}%</p>
            <p className="text-orange-700 font-bold"><strong>Payable:</strong> {payable}</p>
            <p className="text-green-700 font-bold"><strong>Paid:</strong> {paid || 0}.00</p>
            <p className="text-red-700 font-bold"><strong>Pending:</strong> {pending}</p>
          </div>
          <hr />
          <p><strong>Address:</strong> {visitData.opdData?.address}</p>
          <p><strong>Landmark:</strong> {visitData.opdData?.landmark}</p>
          <hr />
          <p><strong>Created At:</strong> {new Date(visitData.opdData?.createdAt).toLocaleString()}</p>
        </div>

      </div>
    </div>
  );
};
