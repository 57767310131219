import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { errorNotifier } from '../../util/notifications';
import { request } from "../../util/fetchAPI";  // Assuming this utility function is used for API requests
import Receipt from "../ReceiptPreview/ReceiptPreview";

export default function VoucherSlipView() {
  const [docName, setDocName] = useState([]);
  const [vouhcerTransec, setVoucherTransec] = useState([]);
  const [vouhcerTransecDtl, setVoucherTransecDtl] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading state
  const { id } = useParams();
  const { register, setValue } = useForm();

  useEffect(() => {
    const fetchVoucherDetails = async () => {
      try {
        const { data, status } = await request(`/voucher/getVoucherSlip/${id}`, "GET");
        if (status === 200) {
          setDocName(data.docName)
          setVoucherTransec(data.transactions);
          setVoucherTransecDtl(data.transectionDtl)
          Object.keys(data).forEach((key) => {
            setValue(key, data[key]);
          });
        } else {
          errorNotifier("Failed to fetch voucher details");
        }
      } catch (error) {
        console.error("An error occurred while fetching the voucher details:", error.message);
      } finally {
        setLoading(false);  // Set loading to false when the request finishes
      }
    };

    if (id) {
      fetchVoucherDetails();
    }
  }, [id, setValue]);


  return (
    <div className="FormContainer">
      <ToastContainer />
      <div className="Heading">Voucher Slip Details</div>

      {loading ? (  // Show loader while loading
        <div className="loaderContainer">
          <span className="loader"></span>
        </div>
      ) : (
        <div className="FormWrapper">
          <form className="forms">
            {/* Slip Number and Voucher ID */}
            <div className="InputBox">
              <div>
                <label htmlFor="slipNumber">Slip Number</label>
                <input
                  type="text"
                  id="slipNo"
                  {...register("slipNo")}
                  readOnly
                />
              </div>
            </div>

            {/* Voucher Type and Payment Method */}
            <div className="InputBox">
              <div>
                <label htmlFor="voucherType">Voucher Type</label>
                <input
                  type="text"
                  id="voucherType"
                  {...register("voucherType")}
                  readOnly
                />
              </div>

              <div>
                <label htmlFor="amount">Amount</label>
                <input
                  type="text"
                  id="amount"
                  {...register("amount")}
                  readOnly
                />
              </div>
            </div>
          </form>
        </div>
      )}
  <div className="flex w-[100%] min-h-[30vh] p-3 bg-gray-50">
        <div className="w-[50%] border-r-2 border-black p-3">
          <div>
            <h1 className="">Debit Account</h1>
            {
              vouhcerTransec
                ?.filter((data) => data.role === "debit")
                .map((data, index) => (
                  <div key={index} className="flex flex-col p-3">
                    <div className="flex gap-2">
                      <span className="font-semibold">Account Id:</span>
                      <span>
                        {data.transaction?.accountId || data.transaction?.bankId}{" "}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Account Name:</span>
                      <span>
                        {data.transaction?.accountName || data.transaction?.bankName}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Role:</span>
                      <span>{data.role}</span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Amount:</span>
                      <span>{data.amount}.00</span>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>

        <div className="w-[50%] p-3">
          <div>
            <h1 className="">Credit Account</h1>
            {
              vouhcerTransec
                ?.filter((data) => data.role === "credit")
                .map((data, index) => (
                  <div key={index} className="flex flex-col p-3">
                    <div className="flex gap-2">
                      <span className="font-semibold">Account Id:</span>
                      <span>
                        {data.transaction?.accountId || data.transaction?.bankId}{" "}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Account Name:</span>
                      <span>
                        {data.transaction?.accountName || data.transaction?.bankName}
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Role:</span>
                      <span>{data.role}</span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-semibold">Amount:</span>
                      <span>{data.amount}.00</span>
                    </div>
                  </div>
                ))
            }
          </div>
        </div>
      </div>
      <Receipt
      data={vouhcerTransecDtl}
      docName={docName}
      />

    </div>
  );
}
