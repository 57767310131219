import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useDocIds() {

    const [isLoading, setIsLoading] = useState(true);
    const [docIdData, setDocIdData] = useState([]);
    const [error, setError] = useState(null);

    const fetchDocIdData = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/documentId/getAllDocumentIds`, "GET");
            setDocIdData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDocIdData();
    }, []);

    return { isLoading, docIdData, error, fetchDocIdData };
}
