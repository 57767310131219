import { useState, useEffect } from "react";
import { request } from "../util/fetchAPI";

const useAccountById = (accountId) => {
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch account data by ID
  const fetchAccountData = async () => {
    setIsLoading(true);
    try {
      const response = await request(`/account/getAccountBy/${accountId}`, "GET");
      if (response.status === 200) {
        setAccountData(response.data);
      } else {
        throw new Error(response.data.message || "Failed to fetch account data");
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Update account data
  const updateAccountData = async (id, data) => {
    setIsLoading(true);
    try {
      const response = await request(`/account/updateAccount/${id}`, "PUT", {
        "Content-Type": "application/json",
      }, data);
      if (response.status === 200) {
        setAccountData(response.data);
        return response.data;
      } else {
        throw new Error(response.data.message || "Failed to update account");
      }
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { accountData, isLoading, error, fetchAccountData, updateAccountData };
};

export default useAccountById;
