import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { request } from '../../util/fetchAPI';

export default function ChangePwdModal({ isModalOpen, handleCancel, userData }) {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();

  const newPwd = watch('newPwd');
  const confirmPwd = watch('confirmPwd');

  const onSubmit = async (data) => {
    try {

      if (data.newPwd !== data.confirmPwd) {
        setError('confirmPwd', { message: 'Passwords do not match' });
        return;
      }
      clearErrors('confirmPwd');

      const response = await request(`/user/changeUserPwd/${userData._id}`, 'POST', {
        'Content-Type': 'application/json',
      }, data);

      if (response.status === 200) {
        successNotifier(response.data.message);
        reset();
        handleCancel(); // Close modal after success
      } else {
        errorNotifier(response.data.message);
      }
    } catch (error) {
      console.error(error);
      errorNotifier('Unexpected error occurred');
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal
        width="50%"
        title="Change Password"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="w-[100%]">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="InputBox">
              <div>
                <label>User Name</label>
                <input
                  className="form-control p-2"
                  type="text"
                  value={userData.userId}
                  placeholder="Enter your username"
                />
                {errors.userName && <span className="text-red-600">{errors.userName.message}</span>}
              </div>

              <div>
                <label>Module</label>
                <input
                  className="form-control p-2"
                  type="text"
                  value={userData.moduleName}
                  placeholder="Enter module name"
                />
                {errors.module && <span className="text-red-600">{errors.module.message}</span>}
              </div>
            </div>

            <div className="InputBox">
              <div>
                <label>New Password</label>
                <input
                  id="newPwdInput"
                  className="form-control p-2"
                  type={showPassword ? 'text' : 'password'}
                  {...register('newPwd', { required: 'New Password is required' })}
                />
                {errors.newPwd && <span className="text-red-600">{errors.newPwd.message}</span>}
              </div>
              <div>
                <label>Confirm Password</label>
                <input
                  id="confirmPwdInput"
                  className="form-control p-2"
                  type={showPassword ? 'text' : 'password'}
                  {...register('confirmPwd', { required: 'Please confirm your password' })}
                />
                {errors.confirmPwd && (
                  <span className="text-red-600">{errors.confirmPwd.message}</span>
                )}
              </div>
            </div>

            <div className="pt-2">
              Show Password
              <input
                type="checkbox"
                className="form-check-input ml-1"
                id="gridCheck"
                onChange={() => setShowPassword(!showPassword)}
              />
            </div>

            <div className="Button">
              <button className="SubButton" disabled={isSubmitting}>
                {isSubmitting ? (
                  <>
                    Please wait
                    <Spin className="text-white font-bold ml-2" indicator={<LoadingOutlined spin />} />
                  </>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
