import React from 'react';
import moment from "moment";

export default function Receipt({data,docName}) {

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-lg rounded-lg p-6">
        {/* Header */}
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold">{docName === "voucher" ?"Voucher" : "Receipt" } </h1>
        </div>

        {docName === "receipt" &&
        <div>
        <div className="flex justify-between items-center">
          <div className="">
            <p>
              <em>Date: {moment(data?.createdAt).format("YYYY-MM-DD")}</em>
            </p>
            <p>
              <em>Receipt #: {data?.prefix} - {data?.voucherId} {data?.receiptNo}</em>
            </p>
            <p>
              <em>Paid By :  {data?.patientId?.patientName}
              ({data?.patientId?.prefix} - {data?.patientId?.patientId})</em>
            </p>
            <p>
              <em>Contact :  {data?.patientId?.contact}</em>
            </p>
            <p>
              <em>Receipt Type : {data?.receiptType}</em>
            </p>
          </div>
        </div>

        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Visit Id</th>
                <th className="text-center py-2">Checked By</th>
                <th className="text-center py-2">Status</th>
                <th className="text-center py-2">Visit Date</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="py-2"><em>({data?.visitId?.prefix} - {data?.visitId?.visitNo})</em></td>
                <td className="text-center py-2">{data?.visitId?.doctorId}</td>
                <td className="text-center py-2">{data?.visitId?.isClear ? "Clear" : "In-Queue"}</td>
                <td className="text-center py-2">{moment(data?.visitId?.opdDate).format("YYYY-MM-DD")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Account Description</th>
                <th className="text-center py-2">Transec. Type</th>
                <th className="text-center py-2">Account Type</th>
                <th className="text-center py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="py-2"><em>{data?.bankId?.bankName} | Account No :({data?.bankId?.accountNo})</em></td>
                <td className="text-center py-2">{data?.bankId? "Debit" : "Credit"}</td>
                <td className="text-center py-2">{data?.bankId? "Bank" : "Null"}</td>
                <td className="text-center py-2">{data?.amount}</td>
              </tr>

              <tr className="border-t">
                <td></td>
                <td></td>
                <td className="text-right py-2 text-lg font-bold">Total:</td>
                <td className="text-center py-2 text-lg font-bold text-green-600">
                Rs {data?.amount}.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2">Description</th>
                <th className="text-center py-2">Transec. Type</th>
                <th className="text-center py-2">Account Type</th>
                <th className="text-center py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="py-2"><em>{data?.receiptSourceId?.accountName} | Account Id :({data?.receiptSourceId?.accountId})</em></td>
                <td className="text-center py-2">{!data?.bankId? "Debit" : "Credit"}</td>
                <td className="text-center py-2">{data?.receiptSourceId?.accountType}</td>
                <td className="text-center py-2"> Rs {data?.amount}.00</td>
              </tr>

              <tr className="border-t">
                <td></td>
                <td></td>
                <td className="text-right py-2 text-lg font-bold">Total:</td>
                <td className="text-center py-2 text-lg font-bold text-red-600">
                Rs {data?.amount}.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>}

        {docName === "voucher" &&
        <div>
        <div className="flex justify-between items-center">
          <div className="">
            <p>
              <em>Date: {moment(data?.createdAt).format("YYYY-MM-DD")}</em>
            </p>
            <p>
              <em>Payment Method : {data?.paymentMethod}</em>
            </p>
            <p>
              <em>Method Name :{data?.bankId?.bankId} | {data?.bankId?.bankName} | Branch : {data?.bankId?.branchCode} | Account : {data?.bankId?.accountNo}</em>
            </p>
          </div>
        </div>

        <div>
          <table className="w-full border-collapse">
            <thead>
              <tr className="border-b">
              <th className="text-left py-2">Voucher Id</th>
                <th className="text-left py-2">Description</th>
                <th className="text-center py-2">Voucher Type</th>
                <th className="text-center py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="py-2"><em>{data?.prefix} - {data?.voucherId}</em></td>
                <td className="py-2"><em>{data?.description || "..."}</em></td>
                <td className="text-center py-2">{data?.Voucher_Type}</td>
                <td className="text-center py-2">{data?.amount}</td>
              </tr>

              <tr className="border-t">
                <td></td>
                <td></td>
                <td className="text-right py-2 text-lg font-bold">Total:</td>
                <td className="text-center py-2 text-lg font-bold text-green-600">
                Rs {data?.amount}.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>}
      </div>
    </div>
  );
}
