import { useState} from 'react';
import { errorNotifier
 } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function usePatientsById() {
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState([]);
    const [error, setError] = useState(null);

    const fetchPatient = async (id) => {
        try {
            setIsLoading(true);
            if(id){
                const response = await request(`/patient/getPatient/${id}`, "GET");
                if (response && response.data) {
                    setPatientData(response.data);
                } else {
                    throw new Error("Invalid response format");
                }
            }

        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    const updatePatient = async (id, updatedData) => {
        setIsLoading(true);
        setError(null);
        try{
        if(id){
            const response = await request(`/patient/updatePatient/${id}`, "PUT", {"Content-Type": "application/json"}, {...updatedData});
            if (response && response.data) {
                setPatientData(response.data);
            } else {
                throw new Error("Invalid response format");
            }
        }}catch (err) {
          setError(err);
          console.error(err);
          throw err;
        } finally {
          setIsLoading(false);
        }
      };


    return { isLoading, patientData, error, fetchPatient,updatePatient };
}
