import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useEmolyeeForAcc from '../../hooks/useEmolyeeForAcc';
import { ToastContainer } from 'react-toastify';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useForm } from 'react-hook-form';
import { BsExclamationCircle } from 'react-icons/bs';
import { RxCrossCircled } from 'react-icons/rx';
import { request } from '../../util/fetchAPI';

export default function UpdateUserForm() {
    const { id } = useParams();
    const { register, handleSubmit, watch, setValue, reset, formState: { errors } } = useForm();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEmp, setSelectedEmp] = useState(null);

    useEffect(() => {
        fetchUser();
    }, [id]);

    const fetchUser = async () => {
        try {
            const { data } = await request(`/user/getUsers/${id}`, 'GET');
            setSearchTerm(`${data.employeeId?.employeeId} - ${data.employeeId?.employeeName}`);
            setSelectedEmp(data.employeeId);
            setValue('userId', data.userId);
            setValue('isEmployee', data.isEmployee);
            setValue('NIC', data.employeeId?.NIC);
            setValue('moduleName', data.moduleName);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const errorMessages = () => (
        <span className="flex items-center justify-start text-red-700 m-1">
            Required Field <BsExclamationCircle className="m-1 text-lg" />
        </span>
    );

    const updateUserHandler = async (datas) => {
        const payload = {
            userId: datas.userId,
            moduleName: datas.moduleName
        };

        try {
            const { data, status } = await request(`/user/updateUser/${id}`, 'PUT', {
                'Content-Type': 'application/json',
            }, payload);

            if (status === 200) {
                reset();
                successNotifier(data.message);
            } else {
                errorNotifier(data.message);
            }
        } catch (error) {
            console.error('An error occurred while updating user data:', error.message);
        }
    };

    return (
        <div className="FormContainer">
            <ToastContainer />
            <div className="Heading">Update User</div>

            <div className="FormWraper">
                <form onSubmit={handleSubmit(updateUserHandler)} className="forms">
                    <div className="InputBox">
                        <label htmlFor="userId">User Id (Login Id)</label>
                        <input
                            type="text"
                            {...register('userId', { required: true })}
                            className={errors.userId ? 'errorInput' : ''}
                        />
                        {errors.userId && errorMessages()}
                    </div>

                    <div className="InputBox">
                        <label htmlFor="isEmployee">Is Employee</label>
                        <select
                            {...register('isEmployee', { required: true })}
                            className={errors.isEmployee ? 'errorInput' : ''}
                            disabled
                        >
                            <option value="false">No</option>
                            <option value="true">Yes</option>
                        </select>
                        {errors.isEmployee && errorMessages()}
                    </div>

                    {watch('isEmployee') === true && (
                        <div className="InputBox">
                            <label className="text-base font-semibold">Select Employee</label>
                            <input
                                type="text"
                                placeholder="Search For Employee..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                readOnly={!!selectedEmp}
                                className={errors.employee ? 'errorInput' : ''}
                            />
                        </div>
                    )}

                    {watch('isEmployee') === false && (
                        <div className="InputBox">
                            <label htmlFor="userName">User Name</label>
                            <input
                                type="text"
                                {...register('userName', { required: true })}
                                className={errors.userName ? 'errorInput' : ''}
                            />
                            {errors.userName && errorMessages()}
                        </div>
                    )}

                    {watch('isEmployee') === true && (
                        <div className="InputBox">
                            <label htmlFor="NIC">NIC/Passport No. #</label>
                            <input
                                type="text"
                                {...register('NIC', { required: true })}
                                className={errors.NIC ? 'errorInput' : ''}
                                readOnly
                            />
                            {errors.NIC && errorMessages()}
                        </div>
                    )}

                    {watch('isEmployee') === false && (
                        <div className="InputBox">
                            <label htmlFor="userEmail">Email</label>
                            <input
                                type="text"
                                {...register('userEmail', { required: true })}
                                className={errors.userEmail ? 'errorInput' : ''}
                            />
                            {errors.userEmail && errorMessages()}

                            <label htmlFor="phoneNum">Phone</label>
                            <input
                                type="text"
                                {...register('phoneNum', { required: true })}
                                className={errors.phoneNum ? 'errorInput' : ''}
                            />
                            {errors.phoneNum && errorMessages()}
                        </div>
                    )}

                    <div>
                        <label className="block m-3" htmlFor="moduleName">Module Access</label>
                        <select
                            {...register('moduleName', { required: true })}
                            multiple
                            className={`h-[20vh] w-[45%] p-2 border rounded-lg text-base ${errors.moduleName ? 'border-red-500 errorInput' : 'border-gray-300'}`}
                        >
                            <option value="admin" className="hover:bg-blue-100">Admin</option>
                            <option value="finance" className="hover:bg-blue-100">Finance</option>
                            <option value="client" className="hover:bg-blue-100">Client</option>
                            {watch('isEmployee') === true && (
                                <option value="doctor" className="hover:bg-blue-100">Doctor</option>
                            )}
                        </select>
                        {errors.moduleName && errorMessages()}
                    </div>

                    <div className="Button">
                        <button type="submit">Update</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
