import React, { useEffect } from "react";
import printJS from "print-js";
import "./PreviewModal.css";
import usePatientById from "../../hooks/usePatientById";
import useCompany from "../../hooks/useCompany";

export const PatientPrintPreview = ({ id, onClose }) => {
  const { isLoading: isPatientLoading, patientData, error: patientError, fetchPatient } = usePatientById();
  const { isLoading: isCompanyLoading, companyData, error: companyError, refetch: fetchCompanyProfile } = useCompany();

  useEffect(() => {
    if (id) {
      fetchPatient(id);
      fetchCompanyProfile();
    }
  }, [id]);

  const handlePrint = () => {
    if (!patientData || !companyData) {
      console.error("No patient or company data available for printing.");
      return;
    }

    const printContent = `
      <div class="patient-slip">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
          <img src="http://localhost:5002/images/${companyData.logo}" alt="Logo" style="width: 80px; height: 80px; border-radius: 15px;">
          <h1 style="margin: 0; text-align: center;">${companyData.companyName}</h1>
        </div>
        <h2 style="text-align: center;">Patient Details</h2>
        <hr />
        <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px;">
          <p><strong>Patient ID:</strong> ${patientData.prefix} - ${patientData.patientId}</p>
          <p><strong>Name:</strong> ${patientData.patientName}</p>
          <p><strong>Gender:</strong> ${patientData.gender}</p>
          <p><strong>Age:</strong> ${patientData.age}Y</p>
          <p><strong>Occupation:</strong> ${patientData.occupation}</p>
          <p><strong>Contact:</strong> ${patientData.contact}</p>
          <p><strong>Address:</strong> ${patientData.address}</p>
          <p><strong>Status:</strong> ${patientData.isActive ? 'Active' : 'Inactive'}</p>
        </div>
        <hr />
        <p><strong>Created At:</strong> ${new Date(patientData.createdAt).toLocaleString()}</p>

        <div style="
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #ddd;
">
  <p>${companyData.address}</p>
  <p>${companyData.website} - ${companyData.email}</p>
  <p>${companyData.contact1} - ${companyData.contact2}</p>
</div>

      </div>
    `;

    printJS({
      printable: printContent,
      type: "raw-html",
      style: `
        .patient-slip {
          font-family: Arial, sans-serif;
          padding: 20px;
        }
        .patient-slip p {
          margin: 5px 0;
        }
        .patient-slip h1, .patient-slip h2 {
          margin: 5px 0;
        }
      `,
    });
  };

  if (isPatientLoading || isCompanyLoading) {
    return <div>Loading...</div>;
  }

  if (patientError || companyError) {
    return <div>Error loading patient or company data.</div>;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-actions">
          <button onClick={handlePrint}>Print</button>
          <button onClick={onClose}>Close</button>
        </div>
        <div className="preview-content">
          <h1>{companyData.companyName}</h1>
          <h2>Patient Details</h2>
          <hr />
          <div className="grid grid-cols-2">
            <p><strong>Patient ID:</strong> {patientData.prefix} - {patientData.patientId}</p>
            <p><strong>Name:</strong> {patientData.patientName}</p>
            <p><strong>Gender:</strong> {patientData.gender}</p>
            <p><strong>Age:</strong> {patientData.age}</p>
            <p><strong>Occupation:</strong> {patientData.occupation}</p>
            <p><strong>Contact:</strong> {patientData.contact}</p>
            <p><strong>Address:</strong> {patientData.address}</p>
            <p><strong>Status:</strong> {patientData.isActive ? 'Active' : 'Inactive'}</p>
          </div>
          <hr />
          <p><strong>Created At:</strong> {new Date(patientData.createdAt).toLocaleString()}</p>
        </div>
      </div>
    </div>
  );
};
