import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { errorNotifier, successNotifier } from '../../util/notifications';
import { request } from "../../util/fetchAPI";  // Assuming this utility function is used for API requests

export default function BanksForm() {
  const { register, handleSubmit, watch, reset, formState: { errors, isSubmitting } } = useForm();

  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  // Form submission handler
  const submitBankDetails = async (payload) => {
    try {
      const { data, status } = await request("/bank/addBank", "POST", {
        "Content-Type": "application/json",
      }, payload);
      if (status === 200) {
        reset()
        successNotifier(data.message)
      } else {
        errorNotifier(data.message)
      }

    } catch (error) {
      console.error("An error occurred while submitting the bank details:", error.message);
    }
  };

  return (
    <div className="FormContainer">
      <ToastContainer />
      <div className="Heading">Add Bank Details</div>

      <div className="FormWrapper">
        <form onSubmit={handleSubmit(submitBankDetails)} className="forms">
          {/* Bank Name and Branch Code */}
          <div className="InputBox">
            <div>
              <label htmlFor="bankId">Bank Id</label>
              <input
                type="text"
                id="bankId"
                placeholder="Id e.g 120 123"
                {...register("bankId", { required: true })}
                className={errors.bankId ? "errorInput" : ""}
              />
              {errors.bankId && errorMessages()}
            </div>
            <div>
              <label htmlFor="bankName">Bank Name</label>
              <input
                type="text"
                id="bankName"
                placeholder="e.g Bank Alfala"
                {...register("bankName", { required: true })}
                className={errors.bankName ? "errorInput" : ""}
              />
              {errors.bankName && errorMessages()}
            </div>

          </div>

          {/* Bank Address and Account Number */}
          <div className="InputBox">
            <div>
              <label htmlFor="branchCode">Branch Code</label>
              <input
                type="text"
                id="branchCode"
                placeholder="Bank Branch Code e.g 1234"
                {...register("branchCode", { required: true })}
                className={errors.branchCode ? "errorInput" : ""}
              />
              {errors.branchCode && errorMessages()}
            </div>
            <div>
              <label htmlFor="bankAddress">Bank Address</label>
              <input
                type="text"
                id="bankAddress"
                placeholder="Bank Adress e.g XYZ"
                {...register("bankAddress", { required: true })}
                className={errors.bankAddress ? "errorInput" : ""}
              />
              {errors.bankAddress && errorMessages()}
            </div>
          </div>

          {/* Minimum Balance and Maximum Balance */}
          <div className="InputBox">
            <div>
              <label htmlFor="accountNo">Account Number/IBAN</label>
              <input
                type="text"
                id="accountNo"
                placeholder="Account Number or IBN"
                {...register("accountNo", { required: true })}
                className={errors.accountNo ? "errorInput" : ""}
              />
              {errors.accountNo && errorMessages()}
            </div>
            <div>
              <label htmlFor="preBalance">Previous Balance</label>
              <input
                type="number"
                id="preBalance"
                placeholder="Bank Balance Right Now"
                min={0}
                {...register("preBalance", { required: true })}
                className={errors.preBalance ? "errorInput" : ""}
              />
              {errors.preBalance && errorMessages()}
            </div>
          </div>

          {/* Submit Button */}
          <div className="Button">

            <button
              className="bg-indigo-900 text-white rounded-sm p-2"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  Please wait{" "}
                  <Spin
                    className="text-white font-bold ml-2"
                    indicator={<LoadingOutlined spin />}
                  />
                </>
              ) : (
                "Submit"
              )}
            </button>

          </div>
        </form>
      </div>
    </div>
  );
}
