import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useDoctors() {
    const [isLoading, setIsLoading] = useState(true);
    const [doctorData, setDoctorData] = useState([]);
    const [error, setError] = useState(null);

    const fetchEmployees = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/doctor/getAllDoctors`, "GET");
            setDoctorData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    return { isLoading, doctorData, error, refetch: fetchEmployees };
}
