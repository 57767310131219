import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useDocumentId() {

    const [isLoading, setIsLoading] = useState(true);
    const [docIdData, setdocIdData] = useState([]);
    const [error, setError] = useState(null);

    const fetchdocIdData = async (name) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/documentId/getDocumentId?documentName=${name}`, "GET");
            setdocIdData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };


    return { isLoading, docIdData, error, fetchdocIdData };
}
