import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import useDeleteData from '../../hooks/useDeleteData';
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import useDocIds from '../../hooks/useDocIds';
import { RxCrossCircled } from "react-icons/rx";
import useAccount from '../../hooks/useAccount';
import { FaSearch } from "react-icons/fa";
import { MdDelete, MdEditDocument } from "react-icons/md";

export default function IdForm() {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const { isLoading: isDeleteLoading, error: deleteError, isDelete, message, setIsDeleted, deleteData } = useDeleteData();
    const { isLoading, docIdData = [], error, fetchDocIdData } = useDocIds();
    const { accountData = [] } = useAccount();
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [filteredIdData, setFilteredIdData] = useState([]);
    const [filteredAccData, setFilteredAccData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchAccountTerm, setSearchAccountTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);
    const [updateId, setUpdateId] = useState(null)

    // Effect to filter employees based on the search term
    useEffect(() => {
        setFilteredIdData(docIdData);
        const filterData = docIdData && docIdData?.filter((data) =>
            data.documentName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredIdData(filterData);
    }, [searchTerm, docIdData]);

    useEffect(() => {
        const filterData = accountData?.filter((data) =>
            data.accountName.toLowerCase().includes(searchAccountTerm.toLowerCase()) ||
            data.accountId.toLowerCase().includes(searchAccountTerm.toLowerCase())
        );
        setFilteredAccData(filterData);
    }, [searchAccountTerm, accountData]);



    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg' /></span>)
    }


    const handleAcctSelect = (data) => {
        setSelectedAccount(data); // Set the selected account
        setSearchAccountTerm(`${data.accountId} - ${data.accountName}`);
        setShowDropdown(false);
        setValue("account", `${data.accountId} - ${data.accountName}`)
    };


    const clearSelectedAcc = () => {
        setSelectedAccount(null);
        setSearchAccountTerm("");
        setValue("account", "");
    };

    const IdFormHandler = async (Data) => {
        const payload = {
            prefix: Data.prefix,
            startingId: Data.startingId,
            currentId: Data.startingId,
            accountId: selectedAccount._id,
            documentName: Data.documentName
        };

        if (updateMode) {
            // Update API call
            const { data, status } = await request(`/documentId/updateDocumentId/${updateId}`, 'PUT', {
                'Content-Type': 'application/json'
            }, { ...payload });

            if (status === 200) {
                successNotifier(data.message);
                fetchDocIdData(); // Refresh the list
            } else {
                errorNotifier(data.message);
            }
        } else {
            // Create API call
            const { data, status } = await request('/documentId/addDocumentId', 'POST', {
                'Content-Type': 'application/json'
            }, { ...payload });

            if (status === 200) {
                successNotifier(data.message);
                fetchDocIdData();
            } else {
                errorNotifier(data.message);
            }
        }

        // Reset form and states
        reset();
        setSelectedAccount(null);
        setSearchAccountTerm("");
        setValue("account", "");
        setUpdateMode(false);
        setUpdateId(null);
    };

    const FetchId = async (id) => {
        const { data, status } = await request(`/documentId/getDocumenIdBy/${id}`, 'GET');
        if (status === 200) {
            setValue("prefix", data.prefix);
            setValue("startingId", data.startingId);
            setValue("documentName", data.documentName);
            setSelectedAccount(data.accountId);
            setSearchAccountTerm(
                `${data.accountId?.accountId} - ${data.accountId?.accountName}`
            );
            setValue("account", `${data.accountId?.accountId} - ${data.accountId?.accountName}`);
            setUpdateMode(true);
            setUpdateId(data._id); // Store the ID of the document being updated
        }
    };

    const handleDelete = (id) => {
        deleteData('documentId', 'deleteDocId', id);
    };

    useEffect(() => {
        if (isDelete) {
            successNotifier(message);
            fetchDocIdData();
            setIsDeleted(false);
        }
    }, [isDelete, fetchDocIdData, message]);



    return (
        <div className='flex flex-col justify-center w-full'>
            <div className='Heading'>
                Generate New Document Id
            </div>

            <div className='flexjustify-center w-[100%] p-3'>
                <form onSubmit={handleSubmit(IdFormHandler)} className='forms w-[100%] min-h-[40vh]'>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="startingId">prefix</label>
                            <input type="text" {...register("prefix", { required: true })} className={errors.prefix ? 'errorInput' : ""} />
                            {errors.prefix && errorMessages()}
                        </div>
                        <div>
                            <label htmlFor="startingId">Starting Id</label>
                            <input type="text" {...register("startingId", { required: true })} className={errors.startingId ? 'errorInput' : ""} />
                            {errors.startingId && errorMessages()}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div className="relative">
                            <label>Select Account</label>
                            <input
                                type="text"
                                placeholder="Search datanation..."
                                value={searchAccountTerm}
                                onChange={(e) => setSearchAccountTerm(e.target.value)}
                                readOnly={!!selectedAccount}
                                className={errors.account ? "errorInput" : ""}
                            />

                            {errors.account && errorMessages()}
                            <div className="absolute inset-y-0 my-auto mt-12 mx-6 right-2 flex items-center">
                                {selectedAccount ? (
                                    <RxCrossCircled
                                        onClick={clearSelectedAcc}
                                        className="cursor-pointer text-red-500 text-xl"
                                    />
                                ) : (
                                    <button
                                        type="button"
                                        onClick={() => setShowDropdown((prev) => !prev)}
                                        className='text-xs text-indigo-700 font-semibold cursor-pointer p-2 border-2 rounded-full border-indigo-700 hover:bg-black hover:text-white'
                                    >
                                        Select
                                    </button>
                                )}
                            </div>

                            {filteredAccData.length > 0 && showDropdown && (
                                <ul className="absolute z-50 min-w-[95%] p-1 mt-1 bg-white border-2 border-gray-400 rounded shadow-2xl">
                                    {filteredAccData.map((data) => (
                                        <li key={data._id} onClick={() => handleAcctSelect(data)}
                                            className="border-b-2 border-b-gray-300 p-2 hover:bg-blue-500 hover:text-white cursor-pointer">
                                            {data.accountId} - {data.accountName}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div>
                            <label htmlFor="documentName" className="block mt-3 mb-1">Select Document</label>
                            <select
                                {...register("documentName", { required: true })}
                                className={errors.documentName ? 'errorInput' : ""}>
                                <option hidden>Select Document</option>
                                <option value="patients">Patients</option>
                                <option value="opd_visit">OPD Visit</option>
                                <option value="home_visit">Home Visit</option>
                                <option value="appointment">Appointment</option>
                                <option value="case">Case</option>
                                <option value="receipt">Receipt</option>
                                <option value="transection">Transections</option>
                                <option value="voucher">Voucher</option>
                                <option value="voucher_slip">Voucher Slip</option>
                                <option value="employee">Employee</option>
                                <option value="department">Department</option>
                                <option value="designation">Designation</option>
                                <option value="supplier">Supplier</option>
                            </select>
                            {errors.documentName && errorMessages()}
                        </div>
                    </div>

                    <div className='Button'>
                        <button>
                            {updateMode ? "Update" : "Submit"}
                        </button>
                    </div>

                </form>
            </div>
            <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
                <ToastContainer />
                <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Document Ids List</h1>
                <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <div className="flex items-center">
                                    <label className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <FaSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="overflow-x-auto">
                            {isLoading ? (
                                <div className='loaderContainer'>
                                    <span className="loader"></span>
                                </div>
                            ) : (
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-4 py-3">#</th>
                                            <th scope="col" className="px-4 py-3">Prefix</th>
                                            <th scope="col" className="px-4 py-3">Staring Id</th>
                                            <th scope="col" className="px-4 py-3">Current Id</th>
                                            <th scope="col" className="px-4 py-3">Account Name</th>
                                            <th scope="col" className="px-4 py-3">Doucument</th>
                                            <th scope="col" className="sr-only text-black"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredIdData && filteredIdData.length > 0 ? (
                                            filteredIdData.map((data, i) => (
                                                <tr key={data._id} className="border-b dark:border-gray-700">
                                                    <td className="px-4 py-3">{i + 1}</td>
                                                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.prefix}</th>
                                                    <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.startingId}</th>
                                                    <td className="px-4 py-3">{data.currentId}</td>
                                                    <td className="px-4 py-3">{data.accountId?.accountId} - {data.accountId?.accountName}</td>
                                                    <td className="px-4 py-3">{data.documentName}</td>
                                                    <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                                                        <MdEditDocument className='text-xl' onClick={() => FetchId(data._id)} />
                                                        <MdDelete className='text-xl' onClick={() => handleDelete(data._id)} />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
