
import React from 'react'
import Department from './Department'
import Designation from './Designation'

export default function DepartDeisg() {
  return (
    <div>
        <Department/>
        <Designation/>
    </div>
  )
}
