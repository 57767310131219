import { useState, useEffect } from 'react';
import { errorNotifier, successNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function usePatients() {
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState([]);
    const [error, setError] = useState(null);

    const fetchClients = async () => {
        try {
            setIsLoading(true);
            const response = await request(`/patient/getAllPatient`, "GET");
            if (response && response.data) {
                setPatientData(response.data);
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchClients();
    }, []);

    return { isLoading, patientData, error, refetch: fetchClients };
}
