import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { request } from "../../util/fetchAPI";

export default function CompanyProfileForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [logoFile, setLogoFile] = useState(null); // Store the selected logo file

  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  // Handle file selection for logo
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogoFile(file); // Store the selected logo file
    }
  };

  // Form submission handler
  const submitCompanyProfile = async (payload) => {
    try {

      const filename = crypto.randomUUID() + logoFile.name;
      const formData = new FormData();
      formData.append("filename", filename);
      formData.append("image", logoFile);

      const { response, status } = await fetch('http://localhost:5002/upload/image', {
        method: "POST",
        headers: {
          /*  "Authorization": `Bearer ${token}`, */
        },
        body: formData,
      });
      if (status === 200) {
        const companyProfileData =
        {
          ...payload,
          logo: filename
        };
        const {data, status} = await request("/company/addCompanyProfile","POST", {
          "Content-Type": "application/json",
        }, { ...companyProfileData })
        if(status === 200 || status === 201){
          window.location.reload()
        }

      }



    } catch (error) {
      console.error("An error occurred while submitting the company profile:", error.message);
    }
  };


  return (
    <div className="FormContainer">
      <div className="Heading">Add Company Profile</div>

      <div className="FormWrapper">
        <form onSubmit={handleSubmit(submitCompanyProfile)} className="forms">
          {/* Company Name and Owner Name */}
          <div className="InputBox">
            <div>
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                id="companyName"
                {...register("companyName", { required: true })}
                className={errors.companyName ? "errorInput" : ""}
              />
              {errors.companyName && errorMessages()}
            </div>
            <div>
              <label htmlFor="ownerName">Owner Name</label>
              <input
                type="text"
                id="ownerName"
                {...register("ownerName", { required: true })}
                className={errors.ownerName ? "errorInput" : ""}
              />
              {errors.ownerName && errorMessages()}
            </div>
          </div>

          {/* Contact 1, Contact 2, and Email */}
          <div className="InputBox">
            <div>
              <label htmlFor="contact1">Contact 1</label>
              <input
                type="text"
                id="contact1"
                {...register("contact1", { required: true })}
                className={errors.contact1 ? "errorInput" : ""}
              />
              {errors.contact1 && errorMessages()}
            </div>
            <div>
              <label htmlFor="contact2">Contact 2</label>
              <input
                type="text"
                id="contact2"
                {...register("contact2")}
                className={errors.contact2 ? "errorInput" : ""}
              />
              {errors.contact2 && errorMessages()}
            </div>
          </div>

          {/* Website and Address */}
          <div className="InputBox">
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                className={errors.email ? "errorInput" : ""}
              />
              {errors.email && errorMessages()}
            </div>
            <div>
              <label htmlFor="website">Website</label>
              <input
                type="text"
                id="website"
                {...register("website")}
                className={errors.website ? "errorInput" : ""}
              />
              {errors.website && errorMessages()}
            </div>
          </div>

          {/* Address and Logo */}
          <div className="InputBox">
            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                {...register("address", { required: true })}
                className={errors.address ? "errorInput" : ""}
              />
              {errors.address && errorMessages()}
            </div>

            <div>
              <label htmlFor="logo">Logo</label>
              <input
                type="file"
                id="logo"
                onChange={handleFileChange}
                className={errors.logo ? "errorInput" : ""}
              />
              {errors.logo && errorMessages()}
            </div>
          </div>

          {/* NTN and STN */}
          <div className="InputBox">
            <div>
              <label htmlFor="NTN">NTN</label>
              <input
                type="text"
                id="NTN"
                {...register("NTN", { required: true })}
                className={errors.NTN ? "errorInput" : ""}
              />
              {errors.NTN && errorMessages()}
            </div>
            <div>
              <label htmlFor="STN">STN</label>
              <input
                type="text"
                id="STN"
                {...register("STN", { required: true })}
                className={errors.STN ? "errorInput" : ""}
              />
              {errors.STN && errorMessages()}
            </div>
          </div>

          {/* Submit Button */}
          <div className="Button">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
