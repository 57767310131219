import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MyProfilePic from "../../assets/MyProfile.PNG";
import './MyProfile.css'; // Or use styled-components for CSS-in-JS
import { request } from '../../util/fetchAPI';
import { logout } from "../../redux/authSlice" // Assuming you have this action

export default function MyProfile() {
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const myProfileData = async (id) => {
    try {
      const { data } = await request(`/user/getUsers/${id}`, "GET");
      setUserData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    myProfileData(user._id);
  }, [user._id]);

  // Handle logout
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  // Handle change password navigation
  const handleChangePassword = () => {
    navigate("/changePwd");
  };

  return (
    <div className="card">
      <div className="img">
        <img
          src={MyProfilePic}
          alt="Profile"
        />
      </div>
      <div className="infos">
        <div className="name">
          <h2>{userData?.userId}</h2>
          <h4>{userData?.userEmail}</h4>
        </div>

        <p className="text flex flex-col gap-3">
          <span>Is This User Is Employee: {userData?.isEmployee ? "YES" : "NO"}</span>
          <span>Module: {userData?.moduleName}</span>
        </p>

        {userData?.isEmployee && (
          <div>
            <h1>Employee Information</h1>
            <div className="name">
              <h2>
                {userData?.employeeId?.employeeName} ({userData?.employeeId?.prefix} - {userData?.employeeId?.employeeId})
              </h2>
              <h4>NIC: {userData?.employeeId?.NIC}</h4>
              <h4>Contact: {userData?.employeeId?.contact}</h4>
            </div>
          </div>
        )}

        <div className="links flex gap-3">
          <button className="follow" onClick={handleChangePassword}>Change Password</button>
          <button className="view" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
};
