import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import useDepart from '../../hooks/useDepart';
import useDocumentId from '../../hooks/useDocumentId';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument } from "react-icons/md";
import { IoMdRefreshCircle } from "react-icons/io";

export default function Department() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm();

    const { isLoading, departData, fetchDepartment } = useDepart();
    const { docIdData, fetchdocIdData } = useDocumentId({});
    const [filteredDepData, setFilteredDepData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [updateId, setUpdateId] = useState(null);

    useEffect(() => {
        setFilteredDepData(departData);
        const filterData = departData && departData?.filter((depart) =>
            depart.departId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            depart.departName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDepData(filterData);
    }, [searchTerm, departData]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg' /></span>);
    }

    const DepartFormHandler = async (data) => {
        const payload = {
            prefix: docIdData.prefix,
            departId: docIdData.currentId,
            ...data
        };

        if (updateMode) {
            const { data, status } = await request(`/employee/updateDepart/${updateId}`, "PUT", { "Content-Type": "application/json" }, { ...payload });
            if (status === 200) {
                successNotifier(data.message);
                reset({ departId: `${docIdData.prefix}-${docIdData.currentId}` }); // Reset other fields but retain departId
                setUpdateMode(false);
                fetchDepartment(); // Refresh the list
            } else {
                errorNotifier(data.message);
            }
        } else {
            const { data, status } = await request('/employee/addDepartment', 'POST', {
                'Content-Type': 'application/json'
            }, { ...payload });
            if (status === 200) {
                successNotifier(data.message);
                reset({ departId: `${docIdData.prefix}-${docIdData.currentId}` }); // Reset other fields but retain departId
                fetchDepartment(); // Refresh the list
            } else {
                errorNotifier(data.message);
            }
        }
    };


    const fetchDepartmentById = async (id) => {
        try {
            const { data, status } = await request(`/employee/getDepartBy/${id}`, "GET");
            setValue("departId", data.prefix + '-' + data.departId);
            setValue("departName", data.departName);
            setValue("section", data.section);
            setUpdateMode(true);
            setUpdateId(data._id);
        } catch (error) {
            console.log(error);
            errorNotifier("Unexpected Error");
        }
    };

    useEffect(() => {
        fetchdocIdData("department");
    }, []);

    useEffect(() => {
        if (docIdData?.prefix && docIdData?.currentId) {
            setValue("departId", `${docIdData.prefix}` + -  + `${docIdData.currentId}`);
        }
    }, [docIdData, setValue,]);

    return (
        <div className='flex flex-col justify-center w-full'>
            <div className='Heading'>
                Add New Department
            </div>

            <div className='flexjustify-center w-[100%] p-3'>
                <form onSubmit={handleSubmit(DepartFormHandler)} className='forms w-[100%] min-h-[40vh]'>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">Department Id</label>
                            <input type="text" {...register("departId", { required: true })} className={errors.departId ? 'errorInput' : ""} readOnly />
                            {errors.departId && errorMessages()}
                        </div>
                    </div>

                    <div className='InputBox'>
                        <div>
                            <label htmlFor="name">Department Name</label>
                            <input type="text" {...register("departName", { required: true })} className={errors.departName ? 'errorInput' : ""} />
                            {errors.departName && errorMessages()}
                        </div>
                        <div>
                            <label htmlFor="name">Section</label>
                            <input type="text" {...register("section", { required: true })} className={errors.section ? 'errorInput' : ""} />
                            {errors.section && errorMessages()}
                        </div>
                    </div>

                    <div className='Button'>
                        <button>
                            {updateMode ? "Update" : "Submit"}
                        </button>
                    </div>
                </form>
            </div>

            <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
                <ToastContainer />
                <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Department List</h1>
                <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                    <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                            <div className="w-full md:w-1/2">
                                <div className="flex items-center">
                                    <label className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <FaSearch />
                                        </div>
                                        <input
                                            type="text"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            placeholder="Search"
                                            onChange={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                onClick={() => fetchDepartment()}
                            >
                                <IoMdRefreshCircle className='mr-2 text-xl' />
                                Refresh
                            </button>
                        </div>
                        <div className="overflow-x-auto">
                            {isLoading ? (
                                <div className='loaderContainer'>
                                    <span className="loader"></span>
                                </div>
                            ) : (
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-4 py-3">#</th>
                                            <th scope="col" className="px-4 py-3">Department Id</th>
                                            <th scope="col" className="px-4 py-3">Department Name</th>
                                            <th scope="col" className="px-4 py-3">Section</th>
                                            <th scope="col" className="px-4 py-3">Status</th>
                                            <th scope="col" className="sr-only"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredDepData && filteredDepData.map((depart, index) => (
                                            <tr key={index} className="border-b dark:border-gray-700">
                                                <td className="px-4 py-3">{index + 1}</td>
                                                <td className="px-4 py-3">{depart.prefix} - {depart.departId}</td>
                                                <td className="px-4 py-3">{depart.departName}</td>
                                                <td className="px-4 py-3">{depart.section}</td>
                                                <td className="px-4 py-3">
                                                    <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Active</span>
                                                </td>
                                                <td className="px-4 py-3 flex justify-end">
                                                    <button className="text-blue-600 hover:underline" onClick={() => fetchDepartmentById(depart._id)}>
                                                        <MdEditDocument className='mr-2 text-xl' />
                                                    </button>
                                                    <button className="text-red-600 hover:underline" onClick={() => {}}>
                                                        <MdDelete className='mr-2 text-xl' />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
