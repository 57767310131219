import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import PatientForm from '../PatientForm/PatientForm';
import PatientList from '../PatientList/PatientList';
import PatientView from '../PatientView/PatientView';
import OPDVisitList from '../OPDVisitList/OPDVisitList';
import OPDVisitView from '../OPDVisitView/OPDVisitView';
import Empolyee from '../EmpolyeeForm/EmpolyeeForm';
import EmpList from '../EmpList/EmpList';
import EmpolyeeFormView from '../EmpolyeeFormView/EmpolyeeFormView';
import DoctorList from '../DoctorList/DoctorList';
import UserForm from '../UserForm/UserForm';
import UserList from '../UserList/UserList';
import DepartDeisg from '../DepartDeisg/DepartDeisg';
import IdForm from '../IdForm/IdForm';
import BanksForm from '../BankForm/BanksForm';
import BankList from '../BankList/BankList';
import BankFormView from '../BankFormView/BankFormView';
import AccountForm from '../AccountForm/AccountForm';
import AccountsDlt from '../AccountsDlt/AccountsDlt';
import AccountFormView from '../AccountFormView/AccountFormView';
import ExpenseList from '../ExpenseList/ExpenseList';
import IncomeList from '../IncomeList/IncomeList';
import ReceiptList from '../ReceiptList/ReceiptList';
import ReceiptView from '../ReceiptView/ReceiptView';
import UnverifiedReceiptList from '../UnverifiedReceiptList/UnverifiedReceiptList';
import CompanyProfile from '../CompanyProfile/CompanyProfile';
import VoucherForm from '../VoucherForm/VoucherForm';  // Add VoucherForm
import VoucherList from '../VoucherList/VoucherList';  // Add VoucherList
import VoucherSlipList from '../VoucherSlipList/VoucherSlipList';
import VoucherSlipView from '../VoucherSlip/VoucherSlip';
import SupplierForm from '../SupplierForm/SupplierForm';
import ChangePwd from '../ChangePwd/ChangePwd';
import ChangeUserPwd from '../ChangeUserPwd/ChangeUserPwd';
import MyProfile from '../MyProfile/MyProfile';
import ProfitLossTable from '../ProfitLossStatement/ProfitLossStatement';
import UserViews from '../UserView/UserViews';

export default function AppRoutes() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/myProfile' element={< MyProfile/>} />
        <Route path='/changePwd' element={<ChangePwd />} />
        <Route path='/changeUserPwd/:id' element={<ChangeUserPwd />} />
        <Route path='/patientform' element={<PatientForm />} />
        <Route path='/patientlist' element={<PatientList />} />
        <Route path='/patientView/:id' element={<PatientView />} />
        <Route path='/opdVisitList' element={<OPDVisitList />} />
        <Route path='/opdVisitView/:id' element={<OPDVisitView />} />
        <Route path='/addEmpolyee' element={<Empolyee />} />
        <Route path='/empolyeeList' element={<EmpList />} />
        <Route path='/empolyeeView/:id' element={<EmpolyeeFormView />} />
        <Route path='/DepartDeisg' element={<DepartDeisg />} />
        <Route path='/doctorList' element={<DoctorList />} />
        <Route path='/addUser' element={<UserForm />} />
        <Route path='/userList' element={<UserList />} />
        <Route path='/userView/:id' element={<UserViews />} />
        <Route path='/addIds' element={<IdForm />} />
        <Route path='/addBank' element={<BanksForm />} />
        <Route path='/bankList' element={<BankList />} />
        <Route path='/bankView/:id' element={<BankFormView />} />
        <Route path='/addAccount' element={<AccountForm />} />
        <Route path='/accountDetail' element={<AccountsDlt />} />
        <Route path='/accountView/:id' element={<AccountFormView />} />
        <Route path='/expenseList' element={<ExpenseList />} />
        <Route path='/incomeList' element={<IncomeList />} />
        <Route path='/unverifiedReceiptList' element={<UnverifiedReceiptList />} />
        <Route path='/receiptList' element={<ReceiptList />} />
        <Route path='/receiptView/:id' element={<ReceiptView />} />
        <Route path='/addSupplier' element={<SupplierForm />} />
        <Route path='/addVoucher' element={<VoucherForm />} />
        <Route path='/voucherList' element={<VoucherList />} />
        <Route path='/voucherSlipList' element={<VoucherSlipList />} />
        <Route path='/voucherSlipView/:id' element={<VoucherSlipView />} />
        <Route path='/profitLossStatement' element={<ProfitLossTable />} />
        <Route path='/companyProfile' element={<CompanyProfile />} />
      </Routes>
    </div>
  );
}
