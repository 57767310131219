import React, { useEffect, useState } from 'react';
import { request } from '../../util/fetchAPI';

function ProfitLossTable() {
  const [profitLossData, setProfitLossData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(() => new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(() => new Date().toISOString().slice(0, 10));

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  const fetchData = async () => {
    try {
      const { data, status } = await request(`/account/profitloss?start=${startDate}&end=${endDate}`, "GET");
      setProfitLossData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Failed to fetch data.');
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">
        Monthly Profit/Loss Statement
      </h1>

      <div className="mb-6 flex justify-center items-center space-x-4">
        <div>
          <label className="block text-gray-700 font-semibold mb-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse border border-gray-300 bg-white rounded-lg shadow-md">
          <thead className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white">
            <tr>
              <th className="border border-gray-300 px-6 py-4 text-left font-semibold">Month</th>
              <th className="border border-gray-300 px-6 py-4 text-left font-semibold">Income Statement</th>
              <th className="border border-gray-300 px-6 py-4 text-left font-semibold">Expenses Statement</th>
              <th className="border border-gray-300 px-6 py-4 text-left font-semibold">Profit/Loss</th>
            </tr>
          </thead>
          <tbody>
            <tr className="hover:bg-gray-100 transition duration-200">
              <td className="border border-gray-300 px-6 py-4">{profitLossData.date?.dateFrom} - {profitLossData.date?.dateTo}</td>
              <td className="border border-gray-300 px-6 py-4 text-green-600 font-bold">
                {profitLossData.totalCredit?.toFixed(2)}
                {profitLossData.creditVouchers?.map((data, index) => (
                    <details key={index} className="text-black font-normal mt-2">
                    <summary className="cursor-pointer underline">Details</summary>
                    <div className="flex flex-col gap-2 p-2">
                      <hr />
                      <span>Dated: {data.slipDate}</span>
                      <span>Source Name: {data.docName}</span>
                      <span>Amount: {data.amount}</span>
                      <span>Voucher Slip: {data.slipNo}</span>
                      <h2 className="mt-2 font-semibold">Transactions</h2>
                      {data.transactions?.map((transaction, i) => (
                        <div key={i} className="flex flex-col gap-2 p-1">
                          <span>Account Type: {transaction.accountType}</span>
                          <span>Account: {transaction.account}</span>
                          <span>Role: {transaction.role}</span>
                          <span>Amount: {transaction.amount}</span>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </details>
                ))}
              </td>
              <td className="border border-gray-300 px-6 py-4 text-red-600 font-bold">
                {profitLossData.totalDebit?.toFixed(2)}
                {profitLossData.debitVouchers?.map((data, index) => (
                  <details key={index} className="text-black font-normal mt-2">
                    <summary className="cursor-pointer underline">Details</summary>
                    <div className="flex flex-col gap-2 p-2">
                    <hr />
                      <span>Dated: {data.slipDate}</span>
                      <span>Source Name: {data.docName}</span>
                      <span>Amount: {data.amount}</span>
                      <span>Voucher Slip: {data.slipNo}</span>
                      <h2 className="mt-2 font-semibold">Transactions</h2>
                      {data.transactions?.map((transaction, i) => (
                        <div key={i} className="flex flex-col gap-2 p-1">
                          <span>Account Type: {transaction.accountType}</span>
                          <span>Account: {transaction.account}</span>
                          <span>Role: {transaction.role}</span>
                          <span>Amount: {transaction.amount}</span>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </details>
                ))}
              </td>
              <td className={`border border-gray-300 px-6 py-4 font-bold ${profitLossData.profitLoss >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {profitLossData.profitLoss?.toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {loading &&<div className="flex justify-center w-[100%]">
               <div className="loader"></div>
             </div>}
      {error && <p className="text-center text-red-500 mt-6">{error}</p>}
    </div>
  );
}

export default ProfitLossTable;
