import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';
import React from 'react'

export default function useVisitById() {
    const [isLoading, setIsLoading] = useState(true);
    const [visitData, setVisitData] = useState([]);
    const [error, setError] = useState(null);

    const fetchVisit = async (id) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/opd/getPreviousPatientVisit/${id}`, "GET");
            setVisitData(data || []);
        } catch (error) {
            console.log(error);
            setError(error);
           /*  errorNotifier("Unexpected Error"); */
        } finally {
            setIsLoading(false);
        }
    };


    return { isLoading, visitData, error, fetchVisit };
}