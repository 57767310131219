import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoMdHome } from "react-icons/io";
import { FaClipboardList,FaHospitalUser,FaChartPie } from "react-icons/fa";
import { FaPersonCirclePlus,FaSackDollar,FaReceipt,FaHandshakeSimple  } from "react-icons/fa6";
import { MdAnalytics } from "react-icons/md";
import { ImManWoman } from "react-icons/im";
import { RiBankFill } from "react-icons/ri";
import { GrOrganization } from "react-icons/gr";
import { PiListNumbersFill } from "react-icons/pi";
import { IoReceiptSharp } from "react-icons/io5";
import "./SideBar.css";

export default function SideBar() {
  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const navItems = [
    { path: "/", icon: <IoMdHome />, label: "Home" },
    {
      label: "Patients",
      icon: <FaClipboardList />,
      subItems: [
        { path: "/patientform", label: "Add Patient" },
        { path: "/patientList", label: "Patient List" },
      ],
    },
    /* {
      label: "Treatments",
      icon: <MdLocalHospital />,
      subItems: [
        { path: "/addEmpolyee", label: "Add Treatments" },
        { path: "/addEmpolyee", label: "Treatments List" },
      ],
    }, */
    {
      label: "OPD Visit",
      icon: <FaHospitalUser />,
      subItems: [
        { path: "/opdvisitlist", label: "Visit List" },
      ],
    },
    {
      label: "Employees",
      icon: <ImManWoman />,
      subItems: [
        { path: "/addEmpolyee", label: "Add Employee" },
        { path: "/empolyeeList", label: "Employee List" },
        { path: "/doctorList", label: "Doctors" },
        { path: "/DepartDeisg", label: "Desig & Depart" },
      ],
    },
    {
      label: "Users",
      icon: <FaPersonCirclePlus />,
      subItems: [
        { path: "/addUser", label: "Add User" },
        { path: "/userList", label: "User List" },
      ],
    },
    {
      label: "Banks",
      icon: <RiBankFill />,
      subItems: [
        { path: "/addBank", icon: <PiListNumbersFill />, label: "Add Bank" },
        { path: "/bankList", icon: <PiListNumbersFill />, label: "Banks List" },
      ],
    },
    {
      label: "Finance",
      icon: <FaChartPie />,
      subItems: [
        { path: "/addAccount", label: "Add Account" },
        /* { path: "/accountDetail", label: "Account Details" }, */
        { path: "/expenseList", label: "Expenses Accounts" },
        { path: "/incomeList", label: "Income Accounts" },
        { path: "/unverifiedReceiptList", label: "Unverified Payment" },
/*         { path: "/receiptList", label: "Pending" }, */
        { path: "/receiptList", label: "Receipts" },
      ],
    },
    {
      label: "Vouchers",
      icon: <FaReceipt  />,
      subItems: [
        { path: "/addVoucher", icon: <IoReceiptSharp />, label: "Add Voucher" },
        { path: "/voucherList", icon: <IoReceiptSharp />, label: "Voucher List" },
        { path: "/voucherSlipList", icon: <IoReceiptSharp />, label: "Voucher Slips" },
      ],
    },
  /*   {
      label: "Suppliers",
      icon: <FaHandshakeSimple  />,
      subItems: [
        { path: "/addSupplier", icon: <IoReceiptSharp />, label: "Add Supplier" },
      ],
    }, */

    { path: "/addIds", icon: <PiListNumbersFill />, label: "Generate Doc. Id" },
    { path: "/profitLossStatement", icon: <MdAnalytics />, label: "Profit/Loss St." },
    { path: "/companyProfile", icon: <GrOrganization />, label: "Company Profile" },
  ];

  return (
    <div className="SideContainer">
      <nav>
        <ul>
          {navItems.map((item, index) => (
            <div key={index}>
              {item.subItems ? (
                <div>
                  <div
                    className="flex gap-2 m-1 items-center text-base cursor-pointer"
                    onClick={() => toggleExpand(index)}
                  >
                    <span className="pl-1">{item.icon}</span>
                    <span>{item.label}</span>
                  </div>
                  {expanded === index && (
                    <ul className="submenu">
                      {item.subItems.map((subItem, subIndex) => (
                        <NavLink
                          key={subIndex}
                          to={subItem.path}
                          className={({ isActive }) => (isActive ? "Clicked" : "")}
                        >
                          <li>{subItem.label}</li>
                        </NavLink>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <NavLink
                  to={item.path}
                  className={({ isActive }) => (isActive ? "Clicked" : "")}
                >
                  <li className="flex justify-start items-center text-base">
                    <span>{item.icon}</span>
                    <span>{item.label}</span>
                  </li>
                </NavLink>
              )}
            </div>
          ))}
        </ul>
      </nav>
    </div>
  );
}
