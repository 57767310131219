import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Brush,
} from "recharts";

const data = [
  { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, amt: 2100 },
];

const data2 = [
  { name: "Group A", value: 500 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// Custom shape for Bar
const CustomBarShape = (props) => {
  const { x, y, width, height, fill, stroke } = props;
  return <Rectangle x={x} y={y} width={width} height={height} fill={fill} stroke={stroke} />;
};

// Pie chart custom label render
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class Example extends PureComponent {
  render() {
    return (
      <div>
        {/* Row 1: Two Bar Charts */}
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%", marginBottom: "20px" }}>
          <div style={{ flex: 1, margin: "0 10px" }}>
            <BarChart width={500} height={400} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" shape={<CustomBarShape stroke="blue" />} />
              <Bar dataKey="uv" fill="#82ca9d" shape={<CustomBarShape stroke="purple" />} />
            </BarChart>
          </div>
          <div style={{ flex: 1, margin: "0 10px" }}>
            <BarChart width={500} height={400} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pv" fill="#8884d8" shape={<CustomBarShape stroke="blue" />} />
              <Bar dataKey="uv" fill="#82ca9d" shape={<CustomBarShape stroke="purple" />} />
            </BarChart>
          </div>
        </div>

        {/* Row 2: Four Pie Charts */}
        <div style={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap", width: "100%", marginBottom: "20px" }}>
          {Array(4)
            .fill(null)
            .map((_, index) => (
              <div key={index} style={{ width: "23%", margin: "10px" }}>
                <PieChart width={200} height={200}>
                  <Pie
                    data={data2}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data2.map((entry, idx) => (
                      <Cell key={`cell-${idx}`} fill={COLORS[idx % COLORS.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </div>
            ))}
        </div>

        {/* Row 3: Two Line Charts */}
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
          <div style={{ flex: 1, margin: "0 10px" }}>
            <LineChart
              width={500}
              height={200}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="uv" stroke="#8884d8" />
              <Brush />
            </LineChart>
          </div>
          <div style={{ flex: 1, margin: "0 10px" }}>
            <LineChart
              width={500}
              height={200}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
              <Brush />
            </LineChart>
          </div>
        </div>
      </div>
    );
  }
}
