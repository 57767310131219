import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import useDesig from '../../hooks/useDesig';
import useDocumentId from '../../hooks/useDocumentId';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument } from "react-icons/md";
import { IoMdRefreshCircle } from "react-icons/io";

export default function Designation() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm();

    const { isLoading: isDesigLoading, desigData, error: desigError, fetchDesignation, updateDesig } = useDesig();
    const { docIdData, fetchdocIdData } = useDocumentId({});
    const [filteredDesgData, setFilteredDesgData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [updateMode, setUpdateMode] = useState(false);
    const [updateId, setUpdateId] = useState(null)

    useEffect(() => {
        setFilteredDesgData(desigData);
        const filterData = desigData && desigData?.filter((desigData) =>
            desigData.desigId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            desigData.desigName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDesgData(filterData);
    }, [searchTerm, desigData]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const errorMessages = () => {
        return (<span className='flex items-center justify-start text-red-700 m-1'>Required Field <BsExclamationCircle className='m-1 text-lg' /></span>)
    }

    const DesigFormHandler = async (data) => {

        const payload = {
            prefix: docIdData.prefix,
            desigId: docIdData.currentId,
            ...data
        }

        if (updateMode) {
            const { data, status } = await request(`/employee/updateDesig/${updateId}`, "PUT", { "Content-Type": "application/json" }, { ...payload });
            if (status === 200) {
                successNotifier(data.message);
                reset()
                setUpdateMode(false)
                fetchDesignation(); // Refresh the list
            } else {
                errorNotifier(data.message);
            }
        }
        else {
            const { data, status } = await request('/employee/addDesignation', 'POST', {
                'Content-Type': 'application/json'
            }, { ...payload, })
            if (status === 200) {
                successNotifier(data.message);
                reset()
                fetchDesignation(); // Refresh the list
            } else {
                errorNotifier(data.message);
            }
        }

    }

    const fetchDesignationById = async (id) => {
        try {
            const { data, status } = await request(`/employee/getDesigBy/${id}`, "GET");
            setValue("desigId", data.prefix + '-' + data.desigId)
            setValue("desigName", data.desigName)
            setUpdateMode(true);
            setUpdateId(data._id)
        } catch (error) {
            console.log(error);
            errorNotifier("Unexpected Error");
        }
    };

    useEffect(() => {
        fetchdocIdData("designation");

    }, []);

    useEffect(() => {
        if (docIdData?.prefix && docIdData?.currentId) {
            setValue("desigId", `${docIdData.prefix}` + -  + `${docIdData.currentId}`);
        }
    }, [docIdData, setValue]);
    return (
        <div className='flex flex-col justify-center w-full'>
            <div className=' w-[100%]'>

                <div className='Heading'>
                    Add New Designation
                </div>

                <div className='flexjustify-center w-[100%] p-3'>
                    <form onSubmit={handleSubmit(DesigFormHandler)} className='forms w-[100%] min-h-[30vh]'>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="name">Designation Id</label>
                                <input type="text" {...register("desigId", { required: true })} className={errors.employeeId ? 'errorInput' : ""} readOnly />
                                {errors.employeeId && errorMessages()}

                            </div>
                            <div>
                                <label htmlFor="name">Designation Name</label>
                                <input type="text" {...register("desigName", { required: true })} className={errors.employeeName ? 'errorInput' : ""} />
                                {errors.employeeName && errorMessages()}
                            </div>
                        </div>

                        <div className='Button'>
                            <button>
                                {updateMode ? "Update" : "Submit"}
                            </button>
                        </div>
                    </form>


                    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
                        <ToastContainer />
                        <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Designation List</h1>
                        <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
                            <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
                                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
                                    <div className="w-full md:w-1/2">
                                        <div className="flex items-center">
                                            <label className="sr-only">Search</label>
                                            <div className="relative w-full">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <FaSearch />
                                                </div>
                                                <input
                                                    type="text"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                                    placeholder="Search"
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                        onClick={() => fetchDesignation()}
                                    >
                                        <IoMdRefreshCircle className='mr-2 text-xl' />
                                        Refresh
                                    </button>

                                </div>
                                <div className="overflow-x-auto">
                                    {isDesigLoading ? (
                                        <div className='loaderContainer'>
                                            <span className="loader"></span>
                                        </div>
                                    ) : (
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-4 py-3">#</th>
                                                    <th scope="col" className="px-4 py-3">Designation Id</th>
                                                    <th scope="col" className="px-4 py-3">Designation Name</th>
                                                    <th scope="col" className="px-4 py-3">Status</th>
                                                    <th scope="col" className="sr-only"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredDesgData && filteredDesgData?.map((data, i) => (
                                                    <tr key={data._id} className="border-b dark:border-gray-700">
                                                        <td className="px-4 py-3">{++i}</td>
                                                        <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.prefix} - {data.desigId}</th>
                                                        <td className="px-4 py-3">{data.desigName}</td>
                                                        <td className="px-4 py-3">  <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Active</span></td>
                                                        <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                                                            <MdEditDocument className='text-xl' onClick={() => fetchDesignationById(data._id)} />
                                                            <MdDelete className='text-xl' />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}
