import React, { useEffect, useState } from 'react';
import useOPDVisitById from '../../hooks/useOPDVisitById';
import useDoctors from '../../hooks/useDoctors';
import { RxCrossCircled } from "react-icons/rx";
import { BsExclamationCircle } from "react-icons/bs";
import { useForm } from 'react-hook-form';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useParams } from 'react-router-dom';

export default function OPDVisitView() {
  const { id } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isLoading, doctorData, error, refetch: fetchEmployees } = useDoctors()
  const { visitData, fetchVisit, updateVisit } = useOPDVisitById()
  const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm({
    defaultValues: { discount: 0 },
  });
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    fetchVisit(id)
  }, [id])

  useEffect(() => {
    // Populate form fields when visitData is fetched
    if (visitData) {
      setValue("department", visitData.opdData?.doctorId?.specialization);
      setValue("specialization", visitData.opdData?.doctorId?.specialization);
      setValue("consultancyFee", visitData?.opdData?.doctorId?.consultancyFee);
      calculatePayableAmount(watch("discount"));
    }
    setSelectedDoctor(visitData.opdData?.doctorId)
    setSearchTerm(`${visitData.opdData?.doctorId?.employeeId} - ${visitData.opdData?.doctorId?.employeeName}`);

  }, [visitData, setValue]);

  useEffect(() => {
    setFilteredData(
      doctorData.filter(doctor =>
        doctor.employeeId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doctor.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, doctorData]);

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor);
    setSearchTerm(`${doctor.employeeId} - ${doctor.employeeName}`);
    setValue("department", doctor.department);
    setValue("specialization", doctor.specialization);
    setValue("consultancyFee", doctor.consultancyFee);
    calculatePayableAmount(watch("discount"));
  };

  const clearSelectedDoctor = () => {
    setSelectedDoctor(null);
    setSearchTerm('');
    setValue("department", '');
    setValue("specialization", '');
    setValue("consultancyFee", '');
    setValue("payableAmount", '');
  };

  const calculatePayableAmount = (discount) => {
    const consultancyFee = selectedDoctor?.consultancyFee || 0;
    setValue("payableAmount", consultancyFee - (consultancyFee * (discount || 0) / 100));
  };

  const OPDVisitHandler = async (formData) => {
    if (!selectedDoctor) {
      errorNotifier("Please select a doctor");
      return;
    }
    const payload = {
      visitId:visitData.opdData?._id,
      doctorId: selectedDoctor._id,
      amount: formData.payableAmount,
      discount: formData.discount,
    };
    setIsSubmitting(true); // Start loading
    try {
      await updateVisit(id, payload); // Call the update API
    } catch (err) {
      console.error("Error updating patient data:", err);
      alert("Failed to update patient data.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };


  useEffect(() => {
    const discountValue = watch("discount");
    calculatePayableAmount(discountValue);
  }, [watch("discount"), selectedDoctor]);


  const errorMessages = () => (
    <span className='flex items-center justify-start text-red-700 m-1'>
      Required Field <BsExclamationCircle className='m-1 text-lg' />
    </span>
  );

  return (
    <div className='flex justify-center items-center overflow-auto'>
      <form className='forms' onSubmit={handleSubmit(OPDVisitHandler)}>
        <div className="InputBox">
          <div>
            <label className='text-base font-semibold'>Visit No.</label>
            <input type="text" value={visitData.opdData?.prefix + "-" + visitData.opdData?.visitNo} readOnly />
            {errors.visitNo && errorMessages()}
          </div>
          <div>
            <label className='text-base font-semibold'>Dated</label>
            <input type="text" value={visitData.opdData?.opdDate} readOnly />
            {errors.dated && errorMessages()}
          </div>
        </div>

        <div className="InputBox">
          <div>
            <label className='text-base font-semibold'>Patient Id</label>
            <input type="text" value={visitData.opdData?.patientId?.patientId + " - " + visitData.opdData?.patientId?.patientName} readOnly />
            {errors.patientId && errorMessages()}
          </div>
          <div>
            <label className='text-base font-semibold'>Contact</label>
            <input type="text" value={visitData.opdData?.patientId?.contact} readOnly />
            {errors.patientName && errorMessages()}
          </div>
        </div>

        <hr className='border-2 border-gray-400 m-5' />

        <div className="InputBox">
          <div className="relative">
            <label className='text-base font-semibold'>Refer To Doctor</label>
            <input
              type="text"
              placeholder="Search For Doctor..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              readOnly={!!selectedDoctor}
              className={errors.consultancyFee ? 'errorInput' : ""}
            />

            {errors.consultancyFee && errorMessages()}
            <div className="absolute inset-y-0 my-auto mt-12 mx-6 right-2 flex items-center">
              {selectedDoctor ? (
                <RxCrossCircled
                  onClick={()=>{if(!visitData?.opdData?.isClear){clearSelectedDoctor()}}}
                  className="cursor-pointer text-red-500 text-xl"
                />
              ) : (
                <span className='text-xs text-indigo-700 font-semibold cursor-pointer p-2 border-2 rounded-full border-indigo-700 hover:bg-black hover:text-white'
                  onClick={() => setShowDropdown((prev) => !prev)}>SELECT</span>
              )}
            </div>
            {showDropdown && filteredData.length > 0 && !visitData?.opdData?.isClear &&(
              <ul className="absolute z-50 min-w-[95%] p-1 mt-1 bg-white border-2 border-gray-400 rounded shadow-2xl">
                {filteredData.map((doctor, index) => (
                  <li
                    key={index}
                    className="border-b-2 border-b-gray-300 p-2 hover:bg-blue-500 hover:text-white cursor-pointer"
                    onClick={() => handleDoctorSelect(doctor)}
                  >
                    {doctor.employeeId} - {doctor.employeeName}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div>
            <label className='text-base font-semibold'>Specialization</label>
            <input type="text" {...register("specialization", { required: true })} readOnly className={errors.specialization ? 'errorInput' : ""} />
            {errors.specialization && errorMessages()}
          </div>

        </div>

        <div className="InputBox">

          <div>
            <label className='text-base font-semibold'>Consultancy Fees</label>
            <input type="text" {...register("consultancyFee", { required: true })} readOnly className={errors.consultancyFee ? 'errorInput' : ""} />
            {errors.consultancyFee && errorMessages()}
          </div>

          <div>
            <label className='text-base font-semibold'>Discount %</label>
            <input
              type="text"
              {...register("discount", { required: true })}
              className={errors.discount ? 'errorInput' : ""}
            />
            {errors.discount && errorMessages()}
          </div>

        </div>

        <div className="InputBox">
          <div>
            <label className='text-base font-semibold'>Payable</label>
            <input type="text" {...register("payableAmount", { required: true })} readOnly className={errors.payableAmount ? 'errorInput' : ""} />
            {errors.payableAmount && errorMessages()}
          </div>

        </div>

        <hr className='border-2 border-gray-400 m-5' />

        <div className="container mx-auto mt-10">
          <div className="overflow-x-auto">
            <table className="min-w-full border border-gray-200 bg-white shadow-md">
              <thead className="bg-green-600 text-white">
                <tr>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">Receipt Id</th>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">Source</th>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">Discount</th>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 text-left text-sm font-medium uppercase tracking-wider">Dated</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {visitData?.receiptData && visitData.receiptData?.map((row, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-gray-100`}
                  >
                    <td className="px-6 py-4 text-sm font-medium text-gray-900">{row.receiptNo}</td>
                    <td className="px-6 py-4 text-sm text-gray-500">{row.receiptSource}</td>
                    <td className="px-6 py-4 text-sm text-gray-500">{row.discount}%</td>
                    <td className="px-6 py-4 text-sm text-gray-500">{row.amount}.00</td>
                    <td className="px-6 py-4 text-sm text-gray-500">{row.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {!visitData?.opdData?.isClear || visitData?.receiptData?.bankId &&
          <div className="Button">
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Updating..." : "Update"}
          </button>
        </div>}
      </form>
    </div>
  );
}
