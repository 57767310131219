import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useAccount() {

    const [isLoading, setIsLoading] = useState(true);
    const [accountData, setAccountData] = useState([]);
    const [error, setError] = useState(null);

    const fetchAccountData = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/account/getAllAccounts`, "GET");
            setAccountData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAccountData();
    }, []);

    return { isLoading, accountData, error, fetchAccountData };
}
