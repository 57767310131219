import React, { useEffect, useState } from "react";
import useDocumentId from "../../hooks/useDocumentId";
import useBankList from "../../hooks/useBankList";
import useAccount from "../../hooks/useAccount";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import { request } from "../../util/fetchAPI";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

export default function AddBanks() {
  const { docIdData, fetchdocIdData } = useDocumentId({});
  const { bankData, fetchBankData } = useBankList({});
  const { isLoading, accountData = [], error, fetchAccountData } = useAccount();

  const accounts = accountData.filter((data) => data.accountType !== 'others')
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      voucherId: null,
      voucherDate: null,
      paymentMethod: null,
      selectBank: null,
      chequeReferenceNo: null,
      amountIssued: null,
      description: null,
    },
  });

  const paymentMethod = watch("paymentMethod");

  const errorMessages = () => (
    <span className="flex items-center text-red-700 m-1">
      Required Field <BsExclamationCircle className="ml-1 text-lg" />
    </span>
  );

  const bankSelector = (selectedBankId) => {
    const selectedBankData = bankData.find((bank) => bank._id === selectedBankId);
    if (selectedBankData) {
      setValue("accountNumber", selectedBankData.accountNo || "");
      setValue("availableBalance", selectedBankData.currBalance + selectedBankData.preBalance +".00");
    } else {
      console.error("Selected bank data not found.");
    }
  };


  const submitVouDetails = async (Data) => {
    try {
      const payload = {
        prefix: docIdData.prefix,
        voucherId: docIdData.currentId,
        Voucher_Type: Data.Voucher_Type,
        paymentMethod: Data.paymentMethod,
        bankId: Data.selectBank,
        account: Data.accountHead,
        amount: Data.amountIssued,
        description:Data.description
      }

      const { data, status } = await request(
        "/voucher/addVoucher",
        "POST",
        { "Content-Type": "application/json" },
        payload
      )

      if (status === 200) {
        reset()
        successNotifier(data.message)
        fetchAccountData();
      } else {
        errorNotifier(data.message)
      }
    } catch (error) {
      errorNotifier(error)
      console.error("Error:", error.message);
    }
  };

  useEffect(() => {
    fetchdocIdData("voucher");
  }, []);

  useEffect(() => {
    if (docIdData?.prefix && docIdData?.currentId) {
      setValue("voucherId", `${docIdData.prefix}-${docIdData.currentId}`);
    }
  }, [docIdData, setValue]);

  return (
    <div className="FormContainer p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
     <ToastContainer />
      <div className="flex justify-center Heading text-2xl m-auto font-bold">Add Voucher Details</div>

      <form onSubmit={handleSubmit(submitVouDetails)} className="space-y-4">
        {/* Voucher ID and Date */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="voucherId" className="block font-medium mb-1">
              Voucher ID
            </label>
            <input
              type="text"
              id="voucherId"
              {...register("voucherId", { required: true })}
              className={`w-full border rounded-lg px-3 py-2 ${errors.voucherId ? "border-red-500" : "border-gray-300"
                }`}
              disabled
            />
            {errors.voucherId && errorMessages()}
          </div>
          <div>
            <label htmlFor="voucherDate" className="block font-medium mb-1">
              Voucher Date
            </label>
            <input
              type="date"
              id="voucherDate"
              {...register("voucherDate", {
                required: "Voucher date is required",
                validate: (value) =>
                  new Date(value) <= new Date() || "Date cannot be in the future",
              })}
              className={`w-full border rounded-lg px-3 py-2 ${errors.voucherDate ? "border-red-500" : "border-gray-300"
                }`}
            />
            {errors.Voucher_Type && errorMessages()}
          </div>
        </div>
        <div>
          <select
            id="Voucher_Type"
            {...register("Voucher_Type", { required: true })}
            defaultValue=""
            className={`w-full border rounded-lg px-3 py-2 ${errors.Voucher_Type ? "border-red-500" : "border-gray-300"}`}
          >
             <option value="" disabled selected>Select Voucher Type</option>
            <option value="debit">Debit</option>
            <option value="credit">Credit</option>
          </select>
          {errors.Voucher_Type && errorMessages()}
        </div>
        {/* Account Selection */}
        <div>
          <label htmlFor="accountHeadId" className="block font-medium mb-1">
            Select Account
          </label>
          <select
            id="accountHead"
            {...register("accountHead", { required: true })}
            className={`w-full border rounded-lg px-3 py-2 ${errors.accountHead ? "border-red-500" : "border-gray-300"
              }`}
          >
            <option value="" disabled selected>Select Account</option>

            {watch("Voucher_Type") &&

              accounts.filter((data) => watch("Voucher_Type") === "credit" ? data.accountType === 'income' : data.accountType === 'expense')
                .map((data) => (
                  <option key={data._id} value={data._id}>
                    {data.accountName}
                  </option>
                ))}

          </select>
          {errors.bankAccountIssuedTo && errorMessages()}
        </div>

        {/* Payment Method */}
        <div>
          <label htmlFor="paymentMethod" className="block font-medium mb-1">
            Payment Method
          </label>
          <select
            id="paymentMethod"
            {...register("paymentMethod", { required: true })}
            className={`w-full border rounded-lg px-3 py-2 ${errors.paymentMethod ? "border-red-500" : "border-gray-300"
              }`}
          >
            <option value="" disabled selected>Select Payment Method</option>
            <option value="Bank">Bank</option>
            <option value="Cash" disabled>Cash</option>
            <option value="Others" disabled>Others</option>
          </select>
          {errors.paymentMethod && errorMessages()}
        </div>

        {/* Conditional Bank Fields */}
        {paymentMethod === "Bank" && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="selectBank" className="block font-medium mb-1">
                  Select Bank
                </label>
                <select
                  id="selectBank"
                  {...register("selectBank", { required: true })}
                  className={`w-full border rounded-lg px-3 py-2 ${errors.selectBank ? "border-red-500" : "border-gray-300"}`}
                  onChange={(e) => bankSelector(e.target.value)}
                >
                  <option hidden>Select Bank</option>
                  {bankData.map((data) => (
                    <option value={data._id} key={data._id}>
                      {data.bankId} - {data.bankName}
                    </option>
                  ))}
                </select>

                {errors.selectBank && errorMessages()}
              </div>
              <div>
                <label htmlFor="accountNumber" className="block font-medium mb-1">
                  Account Number
                </label>
                <input
                  type="text"
                  id="accountNumber"
                  {...register("accountNumber", { required: true })}
                  className={`w-full border rounded-lg px-3 py-2 ${errors.accountNumber ? "border-red-500" : "border-gray-300"
                    }`}
                  disabled
                />
                {errors.accountNumber && errorMessages()}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="availableBalance" className="block font-medium mb-1">
                  Available Balance
                </label>
                <input
                  type="text"
                  id="availableBalance"
                  {...register("availableBalance", { required: true })}
                  className={`w-full border rounded-lg px-3 py-2 ${errors.availableBalance ? "border-red-500" : "border-gray-300"} ${watch("availableBalance") === 0 ? "text-red-600 font-bold" : ""
                    }`}

                  disabled
                />
                {errors.availableBalance && errorMessages()}
              </div>
              <div>
                <label htmlFor="chequeReferenceNo" className="block font-medium mb-1">
                  Cheque/Reference No
                </label>
                <input
                  type="text"
                  id="chequeReferenceNo"
                  {...register("chequeReferenceNo", { required: true })}
                  className={`w-full border rounded-lg px-3 py-2 ${errors.chequeReferenceNo ? "border-red-500" : "border-gray-300"
                    }`}
                />
                {errors.chequeReferenceNo && errorMessages()}
              </div>
            </div>
          </>
        )}

        {/* Amount and Receiver */}

        <div>
          <label htmlFor="amountIssued" className="block font-medium mb-1">
            Amount
          </label>
          <input
            type="text"
            id="amountIssued"
            {...register("amountIssued", {
              required: true,
              pattern: /^[0-9]+(\.[0-9]{1,2})?$/, // Allows numbers with up to 2 decimal places
            })}
            className={`w-full border rounded-lg px-3 py-2 ${errors.amountIssued ? "border-red-500" : "border-gray-300"
              }`}
          />
          {errors.amountIssued && errorMessages()}
        </div>


        {/* Description */}
        <div>
          <label htmlFor="description" className="block font-medium mb-1">
            Description
          </label>
          <textarea
            id="description"
            {...register("description")}
            className="w-full border rounded-lg px-3 py-2"
          />
        </div>

        {/* Submit Button */}
        <div className="flex gap-2">

          <button
            className="bg-indigo-700 text-white rounded-md p-2"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                Please wait{" "}
                <Spin
                  className="text-white font-bold ml-2"
                  indicator={<LoadingOutlined spin />}
                />
              </>
            ) : (
              "Submit"
            )}
          </button>
          <button
            className="bg-gray-700 text-white rounded-md p-2"
            type="button"
            onClick={() => reset()}
          >
          Reset
          </button>
        </div>
      </form>
    </div>
  );
}
