import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useExpense() {
    const [isLoading, setIsLoading] = useState(true);
    const [expenseData, setExpenseData] = useState([]);
    const [error, setError] = useState(null);

    const fetchExpenseData = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/account/getExpenseAccounts`, "GET")
            setExpenseData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchExpenseData();
    }, []);

    return { isLoading, expenseData, error, refetch: fetchExpenseData };
}
