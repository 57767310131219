import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import SideBar from './components/SideBar/SideBar';
import Contents from './components/Contents/Contents';
import Login from './components/Login/Login';
import useCompany from './hooks/useCompany';
import './App.css';

function App() {
  const { isLoading, isCompanyExit, error, fetchProfile } = useCompany();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
/*
  useEffect(() => {
    // Redirect to /companyProfile if the company does not exist and user is logged in
    if (user && !isCompanyExit) {
      navigate('/companyProfile');
    }
  }, [isCompanyExit, user, navigate]); */

  return (
    <div>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        {/* Add a route for company profile */}
        <Route path="/companyProfile"  />
      </Routes>

      {!user ? (
        <Navigate to="/login" />
      ) : (
        <div className="App">
          <Header />
          <div className="MainSetting">
            <div className="SideBar">
              <SideBar />
            </div>
            <div className="Content">
              <Contents />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
