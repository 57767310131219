import { useState } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useDeleteData() {
    const [isLoading, setIsLoading] = useState(false);
    const [isDelete, setIsDeleted] = useState(false); // State to track deletion status
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const deleteData = async (endpoint, url, id) => {
        if (!id) return; // Exit early if no ID is provided
        try {
            setIsLoading(true);
            const { data, status } = await request(`/${endpoint}/${url}/${id}`, 'DELETE', {});
            if (status === 200 || status === 201) {
                setIsDeleted(true);  // Mark as deleted
                setStatus(status)
                setMessage(data.message)
            }
        } catch (error) {
            console.error(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, isDelete, error,message,status, deleteData, setIsDeleted };
}
