import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import usePatientsById from "../../hooks/usePatientById";
import "./PatientView.css"
import CaseHistory from "../CaseHistory/CaseHistory";
import VisitHistory from "../VisitHistory/VisitHistory";
import PaymentHistory from "../PaymentHistory/PaymentHistory";

export default function PatientView() {

  const { isLoading, patientData, error, fetchPatient, updatePatient } = usePatientsById();
  const [activeTab, setActiveTab] = useState("tab1");
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Fetch patient data on component mount or id change
  useEffect(() => {
    fetchPatient(id);
  }, [id]);

  // Populate form fields when patientData changes
  useEffect(() => {
    if (patientData) {
      Object.keys(patientData).forEach((key) => {
        if (key in patientData) {
          setValue(key, patientData[key]);
        }
      });
    }
  }, [patientData, setValue]);

  const errorMessages = () => (
    <span className="error">
      <BsExclamationCircle /> This field is required
    </span>
  );

  const PatientFormHandler = async (formData) => {
    setIsSubmitting(true); // Start loading
    try {
      await updatePatient(id, formData); // Call the update API
    } catch (err) {
      console.error("Error updating patient data:", err);
      alert("Failed to update patient data.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };


  if (error) {
    return <div className="error">Error fetching patient data: {error.message}</div>;
  }

  return (
    <div className="FormContainer">
      <div className="Heading">Edit Patient Details</div>

      <div className="FormWraper">
        <form onSubmit={handleSubmit(PatientFormHandler)} className="forms h-[80vh] mb-1">
          <div className="InputBox">
            <div>
              <label htmlFor="patientId">Patient ID</label>
              <input
                type="text"
                id="patientId"
                value={patientData?.prefix + "-" + patientData?.patientId}
              />
              {errors.patientId && errorMessages()}
            </div>

            <div>
              <label htmlFor="patientName">Patient Name</label>
              <input
                type="text"
                {...register("patientName", { required: true })}
                className={errors.patientName ? "errorInput" : ""}
              />
              {errors.patientName && errorMessages()}
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="gender">Gender</label>
              <select
                {...register("gender", { required: true })}
                className={errors.gender ? "errorInput" : ""}
              >
                <option hidden>Choose...</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
              {errors.gender && errorMessages()}
            </div>

            <div>
              <label htmlFor="age">Age</label>
              <input
                type="number"
                {...register("age", { required: true })}
                className={errors.age ? "errorInput" : ""}
              />
              {errors.age && errorMessages()}
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="contact">Phone Number</label>
              <input
                type="text"
                {...register("contact", { required: true })}
                className={errors.contact ? "errorInput" : ""}
              />
              {errors.contact && errorMessages()}
            </div>

            <div>
              <label htmlFor="occupation">Occupation</label>
              <input
                type="text"
                {...register("occupation", { required: true })}
                className={errors.occupation ? "errorInput" : ""}
              />
              {errors.occupation && errorMessages()}
            </div>
          </div>

          <div className="InputBox">
            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                {...register("address", { required: true })}
                className={errors.address ? "errorInput" : ""}
              />
              {errors.address && errorMessages()}
            </div>
          </div>

          <div className="Button">
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>

      <div className="tabs-container">
        {/* Tab Links */}
        <div className="tabs">

          <button
            className={`tab-link ${activeTab === "tab1" ? "active" : ""}`}
            onClick={() => handleTabClick("tab1")}
          >
            Cases
          </button>
          <button
            className={`tab-link ${activeTab === "tab2" ? "active" : ""}`}
            onClick={() => handleTabClick("tab2")}
          >
            Visit History
          </button>
          <button
            className={`tab-link ${activeTab === "tab3" ? "active" : ""}`}
            onClick={() => handleTabClick("tab3")}
          >
            Payment History
          </button>
        </div>

        {/* Tab Content */}
        <div className="tabs-content">
          {activeTab === "tab1" && <div className="tab-pane active"><CaseHistory id={id}/></div>}
          {activeTab === "tab2" && <div className="tab-pane active"><VisitHistory id={id}/></div>}
          {activeTab === "tab3" && <div className="tab-pane active"><PaymentHistory id={id}/></div>}
        </div>
      </div>

    </div>
  );
}
