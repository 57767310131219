import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCaseById from '../../hooks/useCaseById';
import { ToastContainer } from 'react-toastify';
import { FaEye } from "react-icons/fa";
import { IoMdRefreshCircle } from "react-icons/io";

export default function CaseHistory({ id }) {
  const { isLoading, caseData, error, fetchCase } = useCaseById();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchCase(id); // Only fetch if `id` exists
    }
  }, [id]);

  useEffect(() => {
    if (caseData) {
      const lowerCaseSearch = searchTerm.toLowerCase();
      const filtered = caseData.filter((caseData) =>
        (caseData.caseNo || "").toLowerCase().includes(lowerCaseSearch)
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, caseData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5">
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Case History</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="relative w-full">
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="w-full md:w-auto flex items-center justify-end space-x-3">
              <button
                type="button"
                className="flex text-white bg-indigo-700 hover:bg-primary-800 rounded-lg p-2 dark:bg-primary-600"
                onClick={() => id && fetchCase(id)} // Ensure `id` is valid
              >
                <IoMdRefreshCircle className="mr-2 text-xl" />
                Refresh
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            {isLoading ? (
              <div className="loaderContainer flex justify-center items-center h-64">
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-4 py-3">#</th>
                    <th className="px-4 py-3">Case No.</th>
                    <th className="px-4 py-3">Medical Condition</th>
                    <th className="px-4 py-3">Primary Complaint</th>
                    <th className="px-4 py-3">Visit Date</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((data, i) => (
                      <tr key={data._id || i} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                        <td className="px-4 py-3">{i + 1}</td>
                        <td className="px-4 py-3">{data.caseNo}</td>
                        <td className="px-4 py-3">{data.medicalCondition}</td>
                        <td className="px-4 py-3">{data.primaryComplaint}</td>
                        <td className="px-4 py-3">{new Date(data.createdAt).toDateString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center py-4">No records found.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
