import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useCompany() {
    const [isLoading, setIsLoading] = useState(true);
    const [companyData, setCompanyData] = useState(null);
    const [error, setError] = useState(null);

    const fetchCompanyProfile = async () => {
        try {
            setIsLoading(true);
            const { data, status } = await request(`/company/getCompanyProfile`, "GET");
            if (status === 200) {
                setCompanyData(data);
            } else {
                throw new Error(`Unexpected status code: ${status}`);
            }
        } catch (err) {
            console.error(err);
            setError(err.message || "An error occurred while fetching company profile.");
            errorNotifier(err.message || "Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCompanyProfile();
    }, []);

    return { isLoading, companyData, error, refetch: fetchCompanyProfile };
}
