import React, { useState, useEffect } from 'react';
import moment from "moment";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdEditDocument, MdAddCircle, MdPrint } from "react-icons/md";
import { request } from '../../util/fetchAPI';
import { IoMdRefreshCircle } from "react-icons/io";

export default function VoucherSlipList() {
  const [voucherData, setVoucherData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();

  // Fetch voucher data on component mount
  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const { data, status } = await request("/voucher/getAllVouchersSlip", "GET");
      if (status === 200) {
        setVoucherData(data);
      } else {
        errorNotifier("Failed to load voucher data.");
      }
    } catch (error) {
      console.log(error);
      errorNotifier("Unexpected error occurred while fetching voucher data.");
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Filter voucher data based on the search term
  useEffect(() => {
    setFilteredData(voucherData);
    const filterData = voucherData && voucherData?.filter((voucher) =>
      voucher.slipNo.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filterData);
  }, [searchTerm, voucherData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const deleteHandler = async (id) => {
    try {
      const { data, status } = await request(`/voucher/deleteVoucherBy/${id}`, "DELETE");
      if (status === 200) {
        successNotifier(data.message);
        setVoucherData(voucherData.filter(voucher => voucher._id !== id)); // Remove from state
      } else {
        errorNotifier(data.message);
      }
    } catch (error) {
      console.log(error);
      errorNotifier("Unexpected Error");
    }
  };

  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Voucher List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search by Voucher ID or Bank Name"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => fetchData()}
              >
                <IoMdRefreshCircle className='mr-2 text-xl' />
                Refresh
              </button>
              <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => navigate("/addVoucher")}
              >
                <MdAddCircle className='mr-2 text-xl' />
                Add New Voucher
              </button>
            </div>
          </div>
          <div className="overflow-x-auto">
            {loading ? (
              <div className='loaderContainer'>
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">#</th>
                    <th scope="col" className="px-6 py-3">Slip ID</th>
                    <th scope="col" className="px-6 py-3">Voucher Type</th>
                    <th scope="col" className="px-6 py-3">Debit Account</th>
                    <th scope="col" className="px-6 py-3">Credit Account</th>
                    <th scope="col" className="px-6 py-3">Amount</th>
                    <th scope="col" className="px-6 py-3">Date</th>
                    <th scope="col" className="px-6 py-3">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((voucher, i) => (
                    <tr key={voucher._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="px-4 py-3">{++i}</td>
                      <td className="px-4 py-3">{voucher.slipNo}</td>
                      <td className={voucher.voucherType === 'credit' ? "px-4 py-3 text-green-800 font-bold": "px-4 py-3 text-red-800 font-bold"}>{voucher.voucherType}</td>
                      {voucher.transactions.map((data, index) => (
                        <td key={index} className={data.role === 'debit' ? "px-4 py-3 text-green-800 font-bold": "px-4 py-3 text-red-800 font-bold"}>{data.accountName1} {data.accountName2}</td>
                      ))}
                      <td className="px-4 py-3">{voucher.amount}.00</td>
                      <td className="px-4 py-3">
                        {moment(voucher.createdAt).format("YYYY-MM-DD")}
                      </td>
                      <td className="px-4 py-3">
                        <button onClick={() => navigate(`/voucherSlipView/${voucher._id}`)} className="btnAction"><FaEye className='text-lg' /></button>
                        <button onClick={() => deleteHandler(voucher._id)} className="btnAction"><MdDelete className='text-xl' /></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
