import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useDesig() {
    const [isLoading, setIsLoading] = useState(true);
    const [desigData, setDesigData] = useState([]);
    const [error, setError] = useState(null);

    const fetchDesignation = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/employee/getAllDesig`, "GET");
            setDesigData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };



    useEffect(() => {
        fetchDesignation();
    }, []);

    return { isLoading, desigData, error, fetchDesignation };
}
