import { useState, useEffect } from "react";
import { errorNotifier } from "../util/notifications";
import { request } from "../util/fetchAPI";

export default function useBankById(bankId) {
  const [isLoading, setIsLoading] = useState(true);
  const [bankData, setBankData] = useState(null);
  const [error, setError] = useState(null);

  // Fetch bank details
  const fetchBankData = async (id) => {
    try {
      if (!id) throw new Error("Bank ID is required");

      setIsLoading(true);
      const { data, status } = await request(`/bank/getBankBy/${id}`, "GET");
      if (status === 200) {
        setBankData(data);
      } else {
        throw new Error("Failed to fetch bank data");
      }
    } catch (error) {
      console.error(error);
      setError(error);
      errorNotifier(error.message || "Unexpected Error");
    } finally {
      setIsLoading(false);
    }
  };

  // Update bank details
  const updateBankData = async (id, updatedData) => {
    try {
      if (!id) throw new Error("Bank ID is required");

      setIsLoading(true);
      const { status } = await request(`/bank/updateBank/${id}`, "PUT",{"Content-Type":"application/json"}, updatedData);
      if (status === 200) {
        fetchBankData(id); // Refresh bank data after successful update
      } else {
        throw new Error("Failed to update bank data");
      }
    } catch (error) {
      console.error(error);
      setError(error);
      errorNotifier(error.message || "Unexpected Error");
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, bankData, error, fetchBankData, updateBankData };
}
