import React from 'react'
import AppRoutes from '../AppRoutes/AppRoutes'

export default function Contents() {
  return (
    <div>
      <AppRoutes/>
    </div>
  )
}
