import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useReceiptDtlById() {
    const [isLoading, setIsLoading] = useState(true);
    const [receiptData, setReceiptData] = useState([]);
    const [error, setError] = useState(null);

    const fetchReceipts = async (id) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/receipt/getReceiptDetail/${id}`, "GET");
            setReceiptData(data || [])
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const updateReceipt = async (id, updatedData) => {
        setIsLoading(true);
        setError(null);
        try{
        if(id){
            const response = await request(`/patient/updatePatient/${id}`, "PUT", {"Content-Type": "application/json"}, {...updatedData});
            if (response && response.data) {
                setReceiptData(response.data);
            } else {
                throw new Error("Invalid response format");
            }
        }}catch (err) {
          setError(err);
          console.error(err);
          throw err;
        } finally {
          setIsLoading(false);
        }
      };


    return { isLoading, receiptData, error, fetchReceipts,updateReceipt };
}
