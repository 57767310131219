import React from 'react';
import './Cards.css';

export default function Cards({CardInfo}) {
  return (
    <div className="row">
     {CardInfo && CardInfo.map((data,index)=>(
          <div className="basic-column">
          <div className="tag-wrapper">
            <div className={`number-card number-card-content${++index}`}>
              <h1 className="number-card-number">{data.title}</h1>
              <div className="number-card-dollars">{data.amount}</div>
              <div className="number-card-divider"></div>
              <div className="number-card-progress-wrapper">
                <div className="tagline number-card-currency">Percentage</div>
                <div className="number-card-progress">{data.per}</div>
              </div>
            </div>
          </div>
        </div>
     ))}
    </div>
  );
}
