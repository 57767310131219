import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useIncome() {
    const [isLoading, setIsLoading] = useState(true);
    const [incomeData, setIncomeData] = useState([]);
    const [error, setError] = useState(null);

    const fetchIncomeData = async () => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/account/getIncomeAccounts`, "GET");
            setIncomeData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchIncomeData();
    }, []);

    return { isLoading, incomeData, error, fetchIncomeData };
}
