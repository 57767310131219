import React, { useState, useEffect } from "react";
import useDeleteData from "../../hooks/useDeleteData";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorNotifier, successNotifier } from "../../util/notifications";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaEye } from "react-icons/fa";
import { MdDelete, MdAddCircle, MdPrint } from "react-icons/md";
import { request } from "../../util/fetchAPI";
import { IoMdRefreshCircle } from "react-icons/io";
import "./BankList.css";

export default function BankList() {
  const { isLoading: isDeleteLoading, error: deleteError, isDelete, setIsDeleted, message, deleteData } = useDeleteData();
  const [bankData, setBankData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsFetching(true); // Start loader
    try {
      const { data, status } = await request("/bank/getAllBanks", "GET");
      if (status === 200) {
        setBankData(data);
      } else {
        errorNotifier("Failed to load bank data.");
      }
    } catch (error) {
      console.log(error);
      errorNotifier("Unexpected error occurred while fetching bank data.");
    } finally {
     setIsFetching(false); // Stop loader
    }
  };

  // Fetch bank data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  // Filter bank data based on the search term
  useEffect(() => {
    const filterData =
      bankData &&
      bankData?.filter(
        (bank) =>
          bank.bankName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          bank.bankId.toLowerCase().includes(searchTerm.toLowerCase())
          ||
          bank.accountNo.toLowerCase().includes(searchTerm.toLowerCase())
      );
    setFilteredData(filterData);
  }, [searchTerm, bankData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = (id) => {
    deleteData("bank", "deleteBank", id);
  };

  useEffect(() => {
    if (isDelete) {
      successNotifier(message);
      fetchData(); // Refetch the banks list after deletion
      setIsDeleted(false); // Reset the isDelete flag to prevent repeated effects
    }
  }, [isDelete]);

  return (
    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className="mb-4 text-2xl font-extrabold leading-none tracking-tight">Bank List</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <FaSearch />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search by Bank Name or Bank ID"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
            <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => fetchData()}
              >
                <IoMdRefreshCircle className='mr-2 text-xl' />
                Refresh
              </button>
              <button
                type="button"
                className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                onClick={() => navigate("/addbank")}
              >
                <MdAddCircle className="mr-2 text-xl" />
                Add New Bank
              </button>
            </div>
          </div>
          <div>
            {isFetching || isDeleteLoading ? (
               <div className="flex justify-center w-[100%]">
               <div className="loader"></div>
             </div>
            ) : filteredData?.length === 0 ? (
              <p className="p-4 text-center text-gray-500">No bank data found.</p>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">#</th>
                    <th scope="col" className="px-4 py-3">Bank ID</th>
                    <th scope="col" className="px-4 py-3">Bank Name</th>
                    <th scope="col" className="px-4 py-3">Account No</th>
                    <th scope="col" className="px-4 py-3">Opening Bal</th>
                    <th scope="col" className="px-4 py-3">Current Bal</th>
                    <th scope="col" className="px-4 py-3">Total</th>
                    <th scope="col" className="px-4 py-3">Status</th>
                    <th scope="col" className="sr-only text-black"></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map((bank, i) => (
                    <tr key={bank._id} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3">{++i}</td>
                      <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{bank.bankId}</th>
                      <td className="px-4 py-3">{bank.bankName}</td>
                      <td className="px-4 py-3">{bank.accountNo}</td>
                      <td className="px-4 py-3">{bank.preBalance}.00</td>
                      <td className="px-4 py-3">{bank.currBalance}.00</td>
                      <td className="px-4 py-3">{bank.preBalance + bank.currBalance}.00</td>
                      <td className="px-4 py-3">{bank.isActive ? "ACTIVE" : "IN-Active"}</td>
                      <td className="px-4 py-3 flex items-center justify-end gap-2 cursor-pointer">
                        <FaEye className="text-xl" onClick={() => navigate(`/bankView/${bank._id}`)} />
                        <MdDelete className="text-xl" onClick={() => handleDelete(bank._id)} />
                        <MdPrint className="text-xl" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
